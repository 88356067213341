import React from 'react'
import { useTranslation } from 'react-i18next'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'

import ManageUser from './ManageUser'
import DeleteAlert from './DeleteAlert'

export default function UsersDetails({ user }) {
  const { t } = useTranslation('proposals')

  const renderUserRole = () => {
    switch (user.role) {
      case 'judge':
        return t('judge')
      case 'secretary':
        return t('secretary')
      case 'specialist':
        return t('specialist')
      default:
        return t('admin')
    }
  }

  return (
    <Grid container alignItems="center" className="border-bottom py-1">
      <Grid item xs={3} className="h5 mb-0">
        {user.firstName} {user.lastName}
      </Grid>
      <Grid item xs={3} className="sm">
        {user.email}
      </Grid>
      <Grid item xs={2} className="sm">
        {user.proffesion}
      </Grid>
      <Grid item xs={1} className="sm">
        {renderUserRole()}
      </Grid>
      <Grid item xs={2} className="sm">
        <Typography align="center">
          {user?.rateFactor ? parseFloat(user.rateFactor).toFixed(2) : 0}
        </Typography>
      </Grid>
      <Grid item xs={1} className="sm">
        <div className="d-flex align-items-center justify-content-end">
          <ManageUser userData={user}>
            <IconButton className="mr-1" size="small" aria-label="Edit">
              <EditIcon fontSize="medium" />
            </IconButton>
          </ManageUser>
          <DeleteAlert user={user} />
        </div>
      </Grid>
    </Grid>
  )
}
