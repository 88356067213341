import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import { styled } from '@material-ui/styles'

import DownloadImage from './DownloadImage'
import CopyToClipboard from './CopyToClipboard'
import { Open3dView } from './Open3dView'
// import XdViewpoints from './XdViewpoints'

const StyledText = styled('div')(({ theme }) => ({
  color: theme.modeColors.textColor,
  // minHeight: '40px',
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: 10,
  fontSize: '11px'
}))

const StyledH5 = styled('h5')(({ theme }) => ({
  color: theme.modeColors.headingColor
}))

const RelativeContainer = styled('div')(() => ({
  position: 'relative'
}))

const AbsoluteElement = styled('div')(() => ({
  position: 'absolute',
  top: '10px',
  right: '5px',
  display: 'flex',
  gap: '5px'
}))

class Elements extends Component {
  state = {
    elementKey: ''
  }

  componentDidMount = () => (document.body.style.overflow = 'auto')

  mouseEnterShowIcons = event => {
    const value = event.currentTarget.getAttribute('value')

    this.setState({ elementKey: value })
  }

  mouseLeaveHideIcons = () => this.setState({ elementKey: '' })

  getTabIndex = (arr, elementId) =>
    Object.keys(arr).findIndex(key => key === elementId)

  getElements = elements => {
    const { compareElements } = this.props
    const activeBorder = id => {
      return compareElements && compareElements.includes(id)
    }

    if (elements) {
      const { reactAppUrl } = this.props

      if (this.props.single) {
        const types = _.uniq(_.map(elements, item => item.type))

        return (
          <div style={{ marginBottom: '5rem' }}>
            {types.map((item, i) => (
              <Fragment key={i}>
                {item.includes('panel') && (
                  <Grid container justifyContent="flex-start" spacing={2}>
                    {this.getElementsByTypes(item, elements)}
                  </Grid>
                )}
              </Fragment>
            ))}
            {/* <XdViewpoints proposalId={this.props.proposalId} /> */}
          </div>
        )
      } else {
        return (
          <Grid container justifyContent="flex-start" spacing={1}>
            {_.map(elements, (value, key) => {
              const elementProposal = this.getElementProposl(key)
              const tabIndex = this.getTabIndex(elements, key)

              return (
                <Grid
                  key={key}
                  onClick={() => {
                    this.props.changeTab && this.props.changeTab(tabIndex)
                    this.props.openSingleElement(value, key, elementProposal)
                  }}
                  style={{
                    width: this.props.zoom * 100,
                    cursor: 'pointer',
                    border: activeBorder(key) ? '1px solid blue' : ''
                  }}
                  className="pb-1"
                  item
                >
                  <StyledH5 className="mb-0">
                    {elementProposal ? elementProposal[1].number : null}
                  </StyledH5>
                  <StyledText className="sm">{value.name}</StyledText>
                  <img
                    style={{
                      width: '100%',
                      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)'
                    }}
                    src={reactAppUrl + value.thumbnail_url}
                    alt={value.name}
                  />
                </Grid>
              )
            })}
          </Grid>
        )
      }
    }
    return null
  }

  getElementItemWidth = () => {
    const { zoom } = this.props
    if (zoom === 6) {
      return '100%'
    }
    if (zoom === 5) {
      return '50%'
    }
    if (zoom === 4) {
      return '33.33%'
    }
    if (zoom === 3) {
      return '25%'
    }
    if (zoom === 2) {
      return '20%'
    }
    if (zoom === 1) {
      return '16.66%'
    }
  }

  getElementsByTypes = (type, elements) => {
    const { reactAppUrl, open3dView } = this.props
    const { elementKey } = this.state
    const width = this.getElementItemWidth()

    return Object.keys(elements).map((key, index) => {
      const value = elements[key]

      if (value.type === type) {
        return (
          <Grid
            key={key}
            onClick={() => this.props.openSingleElement(key, index)}
            style={{
              width,
              paddingBottom: 'calc(100% / 1.414)',
              cursor: 'pointer'
            }}
            className="pb-1 mb-1"
            item
          >
            <StyledText>{index + 1}</StyledText>

            <RelativeContainer
              onMouseEnter={key => this.mouseEnterShowIcons(key)}
              onMouseLeave={this.mouseLeaveHideIcons}
              value={key}
            >
              <img
                style={{
                  width: '100%',
                  minHeight: '100%',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)'
                }}
                src={reactAppUrl + value.thumbnail_url}
                alt={value.name}
              />

              <AbsoluteElement>
                {value.type === 'panel-3d' && (
                  <Open3dView onClick={open3dView} show={key === elementKey} />
                )}
                <DownloadImage
                  imageSrc={reactAppUrl + value.pdf_thumbnail_url}
                  imageName={value.name}
                  show={key === elementKey}
                />
                <CopyToClipboard
                  imageSrc={reactAppUrl + value.pdf_thumbnail_url}
                  show={key === elementKey}
                />
              </AbsoluteElement>
            </RelativeContainer>
          </Grid>
        )
      }
      return null
    })
  }

  getElementProposl = val => {
    const { proposals } = this.props
    const proposal = _.pickBy(proposals, (value, key) => {
      const keys = Object.keys(value.elements)
      return keys.includes(val)
    })

    if (Object.keys(proposal).length > 1) {
      return Object.entries(
        _.pickBy(proposal, (value, key) => value.number !== 1)
      )[0]
    } else {
      return Object.entries(proposal)[0]
    }
  }

  render() {
    const { elements } = this.props
    const getElements = elements ? this.getElements(elements) : null

    return <div>{getElements}</div>
  }
}

const mapStateToProps = state => {
  return {
    proposals: state.proposals.data,
    reactAppUrl: state.proposals.envValues.reactAppUrl
  }
}

export default connect(mapStateToProps, null)(Elements)
