import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import OpenSeaDragon from 'openseadragon'
import queryString from 'query-string'
import Helmet from 'react-helmet'

import { withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import CloseIcon from '@material-ui/icons/Close'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'
import Box from '@material-ui/core/Box'

import Button from '../inputs/Button'
// import AdapterLink from '../util/AdapterLink'
import FullScreenIcon from '../assets/icons/fullscreen.svg'
// import ResetZoom from '../assets/icons/reset-zoom.svg';
import FullWindow from '../assets/icons/window.svg'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { getProposalClass } from '../util/Helper'

const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '3px 26px 3px 12px',
    height: '100%'
  }
}))(InputBase)

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    width: 'calc(100% - 264px)',
    position: 'fixed',
    top: 60,
    right: 264,
    bottom: 0,
    left: 0,
    backgroundColor: '#6e7072',
    zIndex: 1000
  },
  dialogPaper2: {
    borderRadius: 0,
    width: 'calc(100% - 36px)',
    position: 'fixed',
    top: 60,
    right: 264,
    bottom: 0,
    left: 0,
    backgroundColor: '#6e7072',
    zIndex: 1000
  },
  dialogPaper3: {
    borderRadius: 0,
    width: '100%',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#6e7072',
    zIndex: 2000
  },
  container: {
    height: '100%',
    padding: '0 24',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column'
  },
  imageContainer: {
    width: '100%',
    margin: '0',
    background: theme.modeColors.elementImageContainer,
    flex: 1,
    minHeight: '60%'
  },
  thumnails_carousel: {
    padding: '4px 20px 4px'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: '#626262',
    opacity: 0.7,
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: 0
    }
  },
  overlayActive: {
    opacity: 0
  },
  thumbnail: {
    // maxHeight: '6rem',
    // maxWidth: '7rem',
    width: '100%',
    height: '100%',
    // margin: '0 15px',
    cursor: 'pointer',
    // border: '3px solid transparent',
    objectFit: 'cover',

    '&:not(:last-child)': {
      marginRight: '15px'
    }
  },
  zoombuttons: {
    position: 'absolute',
    zIndex: 100,
    top: 28,
    right: '20px',
    display: 'flex',
    alignItems: 'center',

    '& button': {
      paddingBottom: 0
    }
  },
  closeButton: {
    position: 'absolute',
    zIndex: 200,
    left: '20px',
    top: '18px',
    padding: 8
  },
  carousel: {
    // boxShadow: ' 0 -1px 3px 0 rgba(0, 0, 0, 0.2)',
    // background: theme.modeColors.carouselBg,
  },
  formControl: {
    minWidth: 'max-content',
    backgroundColor: '#fff'
  },
  selectItem: {
    display: 'grid',
    gridTemplateColumns: '170px 40px 200px',
    gap: '1rem',
    alignItems: 'center',
    textAlign: 'right',
    overflow: 'hidden',
    fontSize: '14px'
  }
})

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 12,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 3,
        initialSlide: 2
      }
    }
  ]
}

let intervalWatcher

const Element = ({
  isOpen,
  classes,
  link,
  expand,
  noSlider,
  element,
  proposalName,
  close,
  reactAppUrl,
  elements,
  match,
  openSingleElement,
  comparing,
  history,
  proposals,
  changeTab,
  id,
  location,
  proposalsData,
  PublicClasses
}) => {
  const [viewer, setViewer] = useState(null)
  const [loading, setLoading] = useState(true)
  const [inFullScreen, setInFullScreen] = useState(false)
  const [inFullView, setInFullView] = useState(false)

  useEffect(() => {
    const tileSources = getTileSource()

    if (tileSources && viewer) {
      viewer.open(Object.values(tileSources))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements])

  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.overflow = 'hidden';
  //   }
  //   return () => (document.body.style.overflow = 'auto');
  // }, [isOpen]);

  useEffect(() => {
    if (!viewer) return
    const tileSources = getTileSource()
    const query = queryString.parse(location.search)
    const keyIndexObj = Object.keys(tileSources).reduce(
      (result, key, index) => Object.assign(result, { [key]: index }),
      {}
    )

    if (query.start_key) {
      const elementKey = query.start_key
      const oldTileImage = viewer.world.getItemAt(keyIndexObj[id])
      const newTileImage = viewer.world.getItemAt(keyIndexObj[elementKey])

      if (oldTileImage && newTileImage) {
        oldTileImage.setOpacity(0)
        newTileImage.setOpacity(1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements, id, location.search])

  useEffect(() => {
    if (viewer) {
      viewer.addHandler('open', function() {
        intervalWatcher = setInterval(fullyLoadedHandler, 200)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer])

  const areAllFullyLoaded = () => {
    let tiledImage
    const count = viewer.world.getItemCount()
    for (let i = 0; i < count; i++) {
      tiledImage = viewer.world.getItemAt(i)
      if (!tiledImage.getFullyLoaded()) {
        return false
      }
    }
    return true
  }

  const fullyLoadedHandler = () => {
    if (areAllFullyLoaded()) {
      clearInterval(intervalWatcher)
      setLoading(false)
      removeFullyLoadedHandlers() // since presumably you only want to do this once

      const query = queryString.parse(history.location.search)
      if (query.start_index) {
        viewer.world.getItemAt(query.start_index) &&
          viewer.world.getItemAt(query.start_index).setOpacity(1)
      }
    }
  }

  const removeFullyLoadedHandlers = () => {
    let tiledImage
    const count = viewer.world.getItemCount()
    for (let i = 0; i < count; i++) {
      tiledImage = viewer.world.getItemAt(i)
      tiledImage.removeHandler('fully-loaded-change', fullyLoadedHandler)
    }
  }

  const getTileSource = () => {
    let allTitleSources = _.reduce(
      elements,
      (result, element, key) =>
        Object.assign(result, {
          [key]: {
            tileSource:
              reactAppUrl + element.tileSource_url.replace(/ /g, '%20'),
            opacity: key === id ? 1 : 0,
            preload: true,
            height: 0.8
          }
        }),
      {}
    )
    return allTitleSources
  }

  useEffect(() => {
    const renderImage = () => {
      const tileSources = getTileSource()

      if (isOpen) {
        if (!viewer) {
          setViewer(
            OpenSeaDragon({
              id: 'viewer',
              crossOriginPolicy: 'Anonymous',
              zoomInButton: 'zoom-in',
              zoomOutButton: 'zoom-out',
              homeButton: 'reset-zoom',
              toolbar: 'toolbar',
              animationTime: 0.5,
              showNavigationControl: true,
              showFullPageControl: true,
              tileSources: Object.values(tileSources),
              preserveViewport: false,
              imageLoaderLimit: 1,
              minZoomImageRatio: 1,
              overlays: [
                {
                  id: 'overlay-loading',
                  x: 1.5,
                  y: 1.5
                }
              ]
            })
          )
        }
      }
    }
    renderImage()

    return () => {
      // Cleanup logic to ensure the element is removed safely
      const overlayLoading = document.getElementById('overlay-loading')
      if (overlayLoading && overlayLoading.parentNode) {
        overlayLoading.parentNode.removeChild(overlayLoading)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, viewer])

  const elementsThumbnail = () => {
    if (!elements) return
    const elementsKeys = Object.keys(elements)

    return elementsKeys.map((key, index) => {
      const value = elements[key]

      return (
        <div
          id={key}
          key={key}
          onClick={() => updateTheViewer(value, key, index)}
          className="position-relative"
          style={{
            width: '100%',
            paddingBottom: 'calc(100% / 1.414)'
          }}
        >
          <img
            className={classes.thumbnail}
            src={reactAppUrl + value.thumbnail_url}
            alt={value.name}
          />
          <div
            className={key === id ? classes.overlayActive : classes.overlay}
          />
        </div>
      )
    })
  }

  const getParentProposal = elementId => {
    const proposal = _.pickBy(proposals, proposal => {
      const keys = Object.keys(proposal.elements)
      return keys.includes(elementId)
    })
    const proposalKeyValueArr = Object.entries(proposal).flat()

    return proposalKeyValueArr
  }

  const generateElementsKeyIndexObj = () => {
    const tileSources = getTileSource()
    // Cannot use lodash here because I need to use 'index'
    const keyIndexObj = Object.keys(tileSources).reduce(
      (result, key, index) => Object.assign(result, { [key]: index }),
      {}
    )

    return keyIndexObj
  }

  const updateTheViewer = (val, key, index) => {
    const proposalKeyValueArr = getParentProposal(key)
    const keyIndexObj = generateElementsKeyIndexObj()
    const oldTileImage = viewer.world.getItemAt(keyIndexObj[id])
    const newTileImage = viewer.world.getItemAt(keyIndexObj[key])

    if (oldTileImage && newTileImage) {
      oldTileImage.setOpacity(0)
      newTileImage.setOpacity(1)
    }

    if (!comparing) {
      if (match.params.id && key !== id) {
        // In single proposal view
        history.replace({
          // of no proposal id found in match, search for proposal key as params
          pathname: `/proposal/${match.params.id}`,
          search: `view_mode=true&start_key=${key}&start_index=${index}`
        })
      } else if (!match.params.id) {
        // In search result elements view (as modal)
        openSingleElement(val, key, proposalKeyValueArr)
      }
    }

    if (comparing) {
      if (key !== id) {
        openSingleElement(val, key, proposalKeyValueArr)
        changeTab(index)
      }
    }
  }

  const getKeyByValue = (obj, value) =>
    Object.keys(obj).find(key => obj[key] === value)

  const getValueByKey = (obj, targetKey) =>
    _.find(obj, (value, key) => key === targetKey)

  const navigateBetweenImages = navCase => {
    const { start_index } = queryString.parse(location.search)
    const startIndex = Number(start_index)
    const keyIndexObj = generateElementsKeyIndexObj()
    const numOfElements = elements ? Object.keys(elements).length : 0

    if (startIndex >= 0 && startIndex < numOfElements) {
      const targetIndex = navCase === 'next' ? startIndex + 1 : startIndex - 1
      const targetElementId = getKeyByValue(keyIndexObj, targetIndex)

      history.replace({
        pathname: `/proposal/${match.params.id}`,
        search: `view_mode=true&start_key=${targetElementId}&start_index=${targetIndex}`
      })
    }

    if (!startIndex) {
      // From the list of all search result elements, when user clicks open an element, this component receives element ID as props 'id'. Find the index number of this element id.
      const selectedElementIndex = getValueByKey(keyIndexObj, id)
      const targetElementIndex =
        navCase === 'next' ? selectedElementIndex + 1 : selectedElementIndex - 1
      const targetElementId = getKeyByValue(keyIndexObj, targetElementIndex)
      const targetElementValue = getValueByKey(elements, targetElementId)

      updateTheViewer(targetElementValue, targetElementId, targetElementIndex)
    }
  }

  const navigateButtons = () => {
    const { start_index } = queryString.parse(location.search)
    const startIndex = Number(start_index)
    const keyIndexObj = generateElementsKeyIndexObj()
    const selectedElementIndex = getValueByKey(keyIndexObj, id)
    const currentElementIndex = startIndex ? startIndex : selectedElementIndex
    const numOfElements = elements ? Object.keys(elements).length : 0

    return inFullScreen || inFullView ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '5px',
          height: '34px'
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccced0',
            width: '37px',
            height: '37px',
            padding: 0
          }}
          size="xs"
          onClick={() => navigateBetweenImages('previous')}
          disabled={startIndex === 0}
        >
          <ArrowBack fontSize="small" />
        </Button>
        <div
          style={{
            display: 'flex',
            backgroundColor: '#fff',
            borderTop: '1px solid #ccced0',
            borderBottom: '1px solid #ccced0',
            padding: '9px 5px 5px',
            minWidth: 'max-content',
            alignItems: 'center',
            fontSize: '15px',
            maxHeight: '21px',
            lineHeight: '22px'
          }}
        >{`${currentElementIndex + 1} of ${numOfElements}`}</div>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccced0',
            width: '37px',
            height: '37px',
            padding: 0
          }}
          size="xs"
          onClick={() => navigateBetweenImages('next')}
          disabled={startIndex === numOfElements - 1}
        >
          <ArrowForward fontSize="small" />
        </Button>
      </div>
    ) : (
      <div />
    )
  }

  const handleChangeProposal = event => {
    const { start_index } = queryString.parse(location.search)
    const keyIndexObj = generateElementsKeyIndexObj()
    const targetElementId = getKeyByValue(keyIndexObj, start_index)

    history.replace({
      pathname: `/proposal/${event.target.value}`,
      search: `view_mode=true&start_key=${targetElementId}&start_index=${start_index}`
    })
  }

  const handleChangeProposalByOne = navCase => {
    let targetProposalId
    const proposalId = match.params.id
    const proposalKeys = Object.keys(proposals)
    const proposalIdPosition = _.indexOf(proposalKeys, proposalId)

    if (navCase === 'next') {
      const nextProposalId = proposalKeys[proposalIdPosition + 1]
      targetProposalId = nextProposalId
    } else {
      const previousProposalId = proposalKeys[proposalIdPosition - 1]
      targetProposalId = previousProposalId
    }

    history.replace({
      ...location,
      pathname: `/proposal/${targetProposalId}`
    })
  }

  const selectProposalOptions = () => {
    const { search } = queryString.parse(location.search)
    const proposalOptions = _.map(proposals, ({ number, name }, key) => {
      const proposalClass = getProposalClass(key, proposalsData, PublicClasses)
      return {
        id: key,
        number,
        name,
        className: proposalClass?.class || 'Class',
        classColour: proposalClass?.colour || 'transparent'
      }
    })

    return !search && !comparing && (inFullScreen || inFullView) ? (
      <Box display="flex" alignItems="center">
        <Button
          variant="contained"
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccced0',
            borderRight: 0,
            width: '37px',
            height: '37px',
            padding: 0
          }}
          size="xs"
          onClick={() => handleChangeProposalByOne('previous')}
          disabled={Object.keys(proposals).indexOf(match.params.id) === 0}
        >
          <ArrowUpward fontSize="small" />
        </Button>
        <FormControl className={classes.formControl}>
          <Select
            value={match.params.id}
            onChange={handleChangeProposal}
            input={<BootstrapInput />}
            MenuProps={{
              style: { zIndex: 2001 }
            }}
          >
            {proposalOptions.map(
              ({ id, number, name, className, classColour }) => {
                const classNameColor =
                  className !== 'Class' ? 'inherit' : '#fff'
                return (
                  <MenuItem key={id} value={id}>
                    <div className={classes.selectItem}>
                      <Box
                        style={{
                          backgroundColor: classColour,
                          textAlign: 'center',
                          color: classNameColor
                        }}
                        py="5px"
                        px="10px"
                      >
                        {className}
                      </Box>
                      <Box>{number}</Box>
                      <Box style={{ textAlign: 'left', paddingLeft: '15px' }}>
                        {name}
                      </Box>
                    </div>
                  </MenuItem>
                )
              }
            )}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccced0',
            borderLeft: 0,
            width: '37px',
            height: '37px',
            padding: 0
          }}
          size="xs"
          onClick={() => handleChangeProposalByOne('next')}
          disabled={
            Object.keys(proposals).indexOf(match.params.id) ===
            Object.keys(proposals).length - 1
          }
        >
          <ArrowDownward fontSize="small" />
        </Button>
      </Box>
    ) : null
  }

  const closeViewer = () => {
    close()
    viewer.destroy()
    setViewer(null)
  }

  const toggleInFullScreen = () => {
    if (inFullView) return
    setInFullScreen(!inFullScreen)

    !inFullScreen ? viewer.setFullScreen(true) : OpenSeaDragon.exitFullScreen()
  }

  const toggleInFullView = () => {
    if (inFullScreen) return
    setInFullView(!inFullView)
  }

  return (
    <>
      <Helmet>
        {inFullScreen ? (
          <script type="text/javascript">
            {`
            window.onUsersnapCXLoad = function(api) {
              api.destroy();
            }
        `}
          </script>
        ) : (
          <script type="text/javascript">
            {`
            window.onUsersnapCXLoad = function(api) {
              api.init();
              api.hide('${process.env.REACT_APP_USERSNAP_API_KEY}')
              window.Usersnap = api;
            }
            var script = document.createElement('script');
            script.defer = 1;
            script.src = 'https://widget.usersnap.com/global/load/${process.env.REACT_APP_USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad';
            document.getElementsByTagName('head')[0].appendChild(script);
        `}
          </script>
        )}
      </Helmet>
      <div
        className={
          expand
            ? classes.dialogPaper2
            : inFullView
            ? classes.dialogPaper3
            : classes.dialogPaper
        }
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <div className={classes.container}>
          {!inFullView && (
            <div className="position-relative">
              <Fab
                variant="extended"
                className={classes.closeButton}
                onClick={closeViewer}
              >
                <CloseIcon variant="contained" />
              </Fab>
            </div>
          )}

          <div id="toolbar" className={classes.zoombuttons}>
            {/* Previous and next buttons */}
            {inFullScreen || inFullView ? (
              <Box mr="2rem">{navigateButtons()}</Box>
            ) : null}

            <Button
              variant="contained"
              style={{
                backgroundColor: inFullView ? '#ccced0' : '#fff',
                border: '1px solid #ccced0',
                width: '37px',
                height: '37px'
              }}
              size="xs"
              id="full-window"
              onClick={toggleInFullView}
              disabled={inFullScreen}
            >
              <img
                src={FullWindow}
                alt="Full window view"
                style={{ width: '100%', height: '100%' }}
              />
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: inFullScreen ? '#ccced0' : '#fff',
                borderTop: '1px solid #ccced0',
                borderBottom: '1px solid #ccced0',
                width: '37px',
                height: '37px'
              }}
              size="xs"
              id="full-screen"
              onClick={toggleInFullScreen}
              disabled={inFullView}
            >
              <img src={FullScreenIcon} alt="Full screen" />
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#fff',
                border: '1px solid #ccced0',
                width: '37px',
                height: '37px'
              }}
              size="xs"
              id="zoom-out"
            >
              <ZoomOutIcon />
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#fff',
                border: '1px solid #ccced0',
                borderLeft: 0,
                width: '37px',
                height: '37px'
              }}
              size="xs"
              id="zoom-in"
            >
              <ZoomInIcon />
            </Button>

            {/* Select proposals */}
            {inFullScreen || inFullView ? (
              <Box ml="2rem">{selectProposalOptions()}</Box>
            ) : null}
          </div>

          <div
            id="overlay-loading"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%'
            }}
          >
            {loading && <CircularProgress color="inherit" />}
          </div>

          <div className={classes.imageContainer} id="viewer">
            {' '}
          </div>

          {!inFullView ? (
            <div className={classes.carousel}>
              {!noSlider && !loading ? (
                <div className={classes.thumnails_carousel}>
                  <Slider {...settings}> {elementsThumbnail()} </Slider>
                </div>
              ) : null}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    reactAppUrl: state.proposals.envValues.reactAppUrl,
    proposals: state.proposals.data
  }
}

Element.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  link: PropTypes.string,
  expand: PropTypes.bool,
  noSlider: PropTypes.bool,
  element: PropTypes.object,
  close: PropTypes.func.isRequired,
  reactAppUrl: PropTypes.string.isRequired,
  elements: PropTypes.object,
  match: PropTypes.object.isRequired,
  openSingleElement: PropTypes.func,
  comparing: PropTypes.bool,
  history: PropTypes.object.isRequired,
  proposals: PropTypes.object.isRequired,
  changeTab: PropTypes.func,
  id: PropTypes.string
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Element)))
