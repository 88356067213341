import * as React from 'react'
import styled from 'styled-components/macro'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { MdEdit, MdClose } from 'react-icons/md'
import { HiOutlinePlus } from 'react-icons/hi'
import { IoMdRemoveCircle } from 'react-icons/io'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'

import FlexWrapper from './components/FlexWrapper'
import Icon from './components/Icon'
import Button from './components/Button'
import useFirestore from './hooks/useFirestore'
import SelectOptions from './components/SelectOptions'
import ToggleSwitch from './components/ToggleSwitch'

export default function EditDataTable({ items, setItems, tableId }) {
  const { t } = useTranslation('proposals')
  const { updateDocument } = useFirestore('info')
  const allDataTable = items.filter(item => item.id.includes('table'))
  const [showDialog, setShowDialog] = React.useState(false)
  const [selectedTypes, setSelectedTypes] = React.useState({})
  const [allRowsData, setAllRowsData] = React.useState(() => {
    const tablesRowsData = allDataTable.map(table => {
      return [
        [table.id],
        {
          tableName: table.name,
          rows: table.data
        }
      ]
    })
    return Object.fromEntries(tablesRowsData)
  })

  const open = () => setShowDialog(true)

  const close = () => {
    handleSaveRowsData()
    setShowDialog(false)
  }

  const updateUnit = (rowId, unitId, updateValues) => {
    const newRowsData = allRowsData[tableId].rows.map(rowData => {
      if (rowData.id === rowId) {
        const unitIndex = rowData.units.findIndex(unit => unit.id === unitId)
        const updatedUnit = {
          ...rowData.units[unitIndex],
          ...updateValues
        }
        const updatedUnits = [
          ...rowData.units.slice(0, unitIndex),
          updatedUnit,
          ...rowData.units.slice(unitIndex + 1)
        ]

        return {
          ...rowData,
          units: updatedUnits
        }
      }
      return rowData
    })
    return newRowsData
  }

  const handleChangeOption = (rowId, unitId, event) => {
    const { value } = event.target
    setSelectedTypes({ ...selectedTypes, [unitId]: { type: value } })

    const newRowsData = updateUnit(rowId, unitId, { type: value })

    setAllRowsData({
      ...allRowsData,
      [tableId]: {
        ...allRowsData[tableId],
        rows: newRowsData
      }
    })
  }

  const handleChangeSwitchValues = event => {
    const { id, name, value } = event.target
    const rowId = id.split('_')[0]
    const unitId = id.split('_')[1]

    const newRowsData = updateUnit(rowId, unitId, { [name]: value })

    setAllRowsData({
      ...allRowsData,
      [tableId]: {
        ...allRowsData[tableId],
        rows: newRowsData
      }
    })
  }

  const handleAddRow = () => {
    const unitId = uuidv4()
    const newRowsData = [
      ...allRowsData[tableId].rows,
      {
        id: uuidv4(),
        name: '',
        value: 0,
        units: [
          {
            id: unitId,
            type: 'number',
            unit: '',
            value: 0,
            leftValue: '',
            rightValue: ''
          }
        ]
      }
    ]

    setSelectedTypes({ ...selectedTypes, [unitId]: { type: 'number' } })

    setAllRowsData({
      ...allRowsData,
      [tableId]: {
        ...allRowsData[tableId],
        rows: newRowsData
      }
    })
  }

  const handleRemoveRow = rowId => {
    const newRowsData = allRowsData[tableId].rows.filter(
      rowData => rowData.id !== rowId
    )
    setAllRowsData({
      ...allRowsData,
      [tableId]: {
        ...allRowsData[tableId],
        rows: newRowsData
      }
    })
  }

  const handleDuplicateRow = rowId => {
    const selectedRow = allRowsData[tableId].rows.find(
      rowData => rowData.id === rowId
    )
    const newSelectedRowUnits = selectedRow.units.map(unit => ({
      ...unit,
      id: uuidv4()
    }))
    const newRowsData = [
      ...allRowsData[tableId].rows,
      {
        ...selectedRow,
        id: uuidv4(),
        units: newSelectedRowUnits
      }
    ]

    setAllRowsData({
      ...allRowsData,
      [tableId]: {
        ...allRowsData[tableId],
        rows: newRowsData
      }
    })
  }

  const handleChangeDescription = event => {
    const { id, name, value } = event.target
    const rowId = id.split('_')[0]
    const newRowsData = allRowsData[tableId].rows.map(rowData => {
      if (rowData.id === rowId) {
        const updatedRowData = {
          ...rowData,
          description: name === 'description' ? value : rowData.description
        }
        return updatedRowData
      }
      return rowData
    })

    setAllRowsData({
      ...allRowsData,
      [tableId]: {
        ...allRowsData[tableId],
        rows: newRowsData
      }
    })
  }

  const handleChangeShowDescription = rowId => {
    const newRowsData = allRowsData[tableId].rows.map(rowData => {
      if (rowData.id === rowId) {
        const updatedRowData = {
          ...rowData,
          showDescription: !rowData.showDescription
        }
        return updatedRowData
      }
      return rowData
    })

    setAllRowsData({
      ...allRowsData,
      [tableId]: {
        ...allRowsData[tableId],
        rows: newRowsData
      }
    })
  }

  const handleChangeRowName = event => {
    const { id, name, value } = event.target
    const rowId = id.split('_')[0]
    const newRowsData = allRowsData[tableId].rows.map(rowData => {
      if (rowData.id === rowId) {
        const updatedRowData = {
          ...rowData,
          name: name === 'criteria' ? value : rowData.name
        }
        return updatedRowData
      }
      return rowData
    })

    setAllRowsData({
      ...allRowsData,
      [tableId]: {
        ...allRowsData[tableId],
        rows: newRowsData
      }
    })
  }

  const handleChangeUnit = event => {
    const { id, name, value } = event.target
    const rowId = id.split('_')[0]
    const unitId = id.split('_')[1]

    const newRowsData = allRowsData[tableId].rows.map(rowData => {
      if (rowData.id === rowId) {
        const unitIndex = rowData.units.findIndex(unit => unit.id === unitId)
        const updatedUnit = {
          ...rowData.units[unitIndex],
          type: selectedTypes[unitId].type, //remove this?
          unit: name === 'unit' ? value : rowData.units[unitIndex].unit,
          value: name === 'value' ? value : 0
        }
        const updatedUnits = [
          ...rowData.units.slice(0, unitIndex),
          updatedUnit,
          ...rowData.units.slice(unitIndex + 1)
        ]

        return {
          ...rowData,
          units: updatedUnits
        }
      }
      return rowData
    })

    setAllRowsData({
      ...allRowsData,
      [tableId]: {
        ...allRowsData[tableId],
        rows: newRowsData
      }
    })
  }

  const handleAddUnit = rowId => {
    const unitId = uuidv4()
    const newRowsData = allRowsData[tableId].rows.map(rowData => {
      if (rowData.id === rowId) {
        return {
          ...rowData,
          units: [
            ...rowData.units,
            {
              id: unitId,
              type: 'number',
              unit: '',
              value: 0,
              leftValue: '',
              rightValue: ''
            }
          ]
        }
      }
      return rowData
    })

    setSelectedTypes({ ...selectedTypes, [unitId]: { type: 'number' } })

    setAllRowsData({
      ...allRowsData,
      [tableId]: {
        ...allRowsData[tableId],
        rows: newRowsData
      }
    })
  }

  const handleRemoveUnit = (rowId, unitId) => {
    const newRowsData = allRowsData[tableId].rows.map(rowData => {
      if (rowData.id === rowId) {
        const newUnits = rowData.units.filter(unit => unit.id !== unitId)
        return {
          ...rowData,
          units: newUnits
        }
      }
      return rowData
    })

    setAllRowsData({
      ...allRowsData,
      [tableId]: {
        ...allRowsData[tableId],
        rows: newRowsData
      }
    })
  }

  // const handleChangeUnit = event => {
  //   const { id, name, value } = event.target
  //   const rowId = id.split('_')[0]
  //   const unitId = id.split('_')[1]

  //   const newRowsData = allRowsData[tableId].rows.map(rowData => {
  //     if (rowData.id === rowId) {
  //       const unitIndex = rowData.units.findIndex(unit => unit.id === unitId)
  //       const updatedUnit = {
  //         ...rowData.units[unitIndex],
  //         type: selectedTypes[unitId].type,
  //         unit: name === 'unit' ? value : rowData.units[unitIndex].unit,
  //         value: name === 'value' ? value : 0
  //       }
  //       console.log('updatedUnit:', updatedUnit)
  //       const updatedUnits = [
  //         ...rowData.units.slice(0, unitIndex),
  //         updatedUnit,
  //         ...rowData.units.slice(unitIndex + 1)
  //       ]
  //       console.log('updatedUnits:', updatedUnits)
  //       return {
  //         ...rowData,
  //         units: updatedUnits
  //       }
  //     }
  //     return rowData
  //   })
  //   console.log('newRowsData:', newRowsData)

  //   setAllRowsData({
  //     ...allRowsData,
  //     [tableId]: {
  //       ...allRowsData[tableId],
  //       rows: newRowsData
  //     }
  //   })
  // }

  // const handleAddUnit = rowId => {
  //   const unitId = uuidv4()
  //   const newRowsData = allRowsData[tableId].rows.map(rowData => {
  //     if (rowData.id === rowId) {
  //       return {
  //         ...rowData,
  //         units: [
  //           ...rowData.units,
  //           { id: unitId, type: 'number', unit: '', value: 0 }
  //         ]
  //       }
  //     }
  //     return rowData
  //   })

  //   setSelectedTypes({ ...selectedTypes, [unitId]: { type: 'number' } })

  //   setAllRowsData({
  //     ...allRowsData,
  //     [tableId]: {
  //       ...allRowsData[tableId],
  //       rows: newRowsData
  //     }
  //   })
  // }

  // const handleRemoveUnit = (rowId, unitId) => {
  //   const newRowsData = allRowsData[tableId].rows.map(rowData => {
  //     if (rowData.id === rowId) {
  //       const newUnits = rowData.units.filter(unit => unit.id !== unitId)
  //       return {
  //         ...rowData,
  //         units: newUnits
  //       }
  //     }
  //     return rowData
  //   })

  //   setAllRowsData({
  //     ...allRowsData,
  //     [tableId]: {
  //       ...allRowsData[tableId],
  //       rows: newRowsData
  //     }
  //   })
  // }

  const handleSaveRowsData = () => {
    const newItems = items.map(item => {
      if (item.id === tableId) {
        const updatedItem = {
          ...item,
          data: allRowsData[tableId].rows
        }
        return updatedItem
      }
      return item
    })
    updateDocument('submission', { items: newItems })
    setItems(newItems)
  }

  return (
    <>
      <Icon width="25px" height="25px" mr="0" ml="15px">
        <MdEdit onClick={open} />
      </Icon>

      <Dialog
        isOpen={showDialog}
        onDismiss={close}
        aria-label={t('Edit data table')}
      >
        <div style={{ marginBottom: 24 }}>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <h2 style={{ marginBottom: 0 }}>{t('Data entry table')}</h2>
            <Icon width="20px" height="20px" mr="0">
              <MdClose onClick={close} />
            </Icon>
          </FlexWrapper>
        </div>
        <Wrapper>
          <h3>{allRowsData[tableId].tableName}</h3>

          <div style={{ marginBottom: '2rem' }}>
            {allRowsData[tableId].rows.map((item, index) => (
              <TableRow
                key={item?.id}
                item={item}
                handleChangeUnit={handleChangeUnit}
                handleChangeRowName={handleChangeRowName}
                handleChangeDescription={handleChangeDescription}
                handleChangeShowDescription={handleChangeShowDescription}
                handleChangeOption={handleChangeOption}
                handleAddUnit={handleAddUnit}
                handleRemoveUnit={handleRemoveUnit}
                handleRemoveRow={handleRemoveRow}
                handleDuplicateRow={handleDuplicateRow}
                handleChangeSwitchValues={handleChangeSwitchValues}
                selectedTypes={selectedTypes}
              />
            ))}
          </div>

          <FlexWrapper justifyContent="center">
            <Button variant="primary" onClick={handleAddRow}>
              <FlexWrapper alignItems="center">
                <HiOutlinePlus
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: 10
                  }}
                />
                <span>{t('Add row')}</span>
              </FlexWrapper>
            </Button>
          </FlexWrapper>
        </Wrapper>
      </Dialog>
    </>
  )
}

function TableRow({
  item,
  selectedTypes,
  handleChangeUnit,
  handleChangeOption,
  handleChangeRowName,
  handleChangeDescription,
  handleChangeShowDescription,
  handleAddUnit,
  handleRemoveUnit,
  handleRemoveRow,
  handleChangeSwitchValues,
  handleDuplicateRow
}) {
  const { t } = useTranslation('proposals')
  return (
    <div
      style={{
        marginBottom: 20,
        paddingBottom: 20,
        borderBottom: '1px solid #e3e3e3'
      }}
    >
      <Row>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={{ minWidth: 70, marginRight: 10 }}>{t('Name')}</div>
          <InputName
            value={item?.name}
            onChange={handleChangeRowName}
            id={`${item.id}_name`}
            name="criteria"
            placeholder=""
          />
        </div>
      </Row>
      <Row>
        <div style={{ display: 'flex', gap: 10 }}>
          <div style={{ minWidth: 70, marginRight: 10 }}>
            {t('Show description')}
          </div>
          <ToggleSwitch
            itemId={`${item.id}_showDescription`}
            checked={item.showDescription}
            onChange={() => handleChangeShowDescription(item.id)}
          />
        </div>
      </Row>
      <Row>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={{ minWidth: 70, marginRight: 10 }}>
            {t('Description')}
          </div>
          <TextArea
            id={`${item.id}_description`}
            name="description"
            cols="30"
            rows="5"
            onChange={handleChangeDescription}
            value={item?.description}
          ></TextArea>
        </div>
      </Row>
      <Row>
        <div
          style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}
        >
          <div style={{ minWidth: 70, marginRight: 10, paddingTop: 8 }}>
            {t('Units')}
          </div>
          <div style={{ width: '100%' }}>
            {item?.units.map(unit => {
              return (
                <div
                  key={unit?.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'stretch',
                    marginBottom: 15
                  }}
                >
                  <Column style={{ marginRight: 20 }}>
                    <SelectOptions
                      onChange={event =>
                        handleChangeOption(item.id, unit.id, event)
                      }
                      selectedType={unit?.type}
                    />
                  </Column>
                  {unit?.type === 'switch' ? (
                    <div
                      style={{
                        display: 'flex',
                        marginRight: 20,
                        width: '100%'
                      }}
                    >
                      <Column style={{ marginRight: 10, width: '100%' }}>
                        <InputUnit
                          value={unit?.leftValue}
                          onChange={handleChangeSwitchValues}
                          id={`${item.id}_${unit.id}_leftValue`}
                          name="leftValue"
                          placeholder="Left"
                        />
                      </Column>
                      <Column style={{ width: '100%' }}>
                        <InputUnit
                          value={unit?.rightValue}
                          onChange={handleChangeSwitchValues}
                          id={`${item.id}_${unit.id}_rightValue`}
                          name="rightValue"
                          placeholder="Right"
                        />
                      </Column>
                    </div>
                  ) : (
                    <DoubleColumn style={{ marginRight: 20 }}>
                      <InputUnit
                        value={unit?.unit}
                        onChange={handleChangeUnit}
                        id={`${item.id}_${unit.id}_unit`}
                        name="unit"
                        placeholder="Unit"
                      />
                    </DoubleColumn>
                  )}
                  <div
                    onClick={() => handleRemoveUnit(item?.id, unit?.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <IoMdRemoveCircle
                      style={{
                        width: 20,
                        height: 20,
                        paddingTop: 5,
                        color: 'hsl(0, 94%, 60%)'
                      }}
                    />
                  </div>
                </div>
              )
            })}
            <button
              style={{
                border: '1px solid transparent',
                fontSize: '0.9rem',
                padding: '8px 12px',
                backgroundColor: 'hsl(231, 48%, 48%)',
                color: '#fff',
                cursor: 'pointer'
              }}
              variant="primary"
              onClick={() => handleAddUnit(item.id)}
            >
              <FlexWrapper alignItems="center">
                <HiOutlinePlus
                  style={{
                    width: '10px',
                    height: '10px',
                    marginRight: 10
                  }}
                />
                <span>{t('Add unit')}</span>
              </FlexWrapper>
            </button>
          </div>
        </div>
      </Row>
      <div style={{ width: '100%', marginTop: 25, textAlign: 'right' }}>
        {/* <button
          style={{
            border: '2px solid hsl(231, 48%, 48%)',
            fontSize: '0.8rem',
            marginRight: 10,
            padding: '6px 8px 4px',
            backgroundColor: '#fff',
            color: 'hsl(231, 48%, 48%)',
            cursor: 'pointer',
          }}
          onClick={() => handleDuplicateRow(item.id)}
        >
          Duplicate
        </button> */}
        <button
          style={{
            border: '1px solid transparent',
            fontSize: '0.8rem',
            padding: '6px 8px 4px',
            backgroundColor: 'hsl(0, 94%, 60%)',
            color: '#fff',
            cursor: 'pointer'
          }}
          onClick={() => handleRemoveRow(item.id)}
        >
          {t('Remove')}
        </button>
      </div>
    </div>
  )
}

const Wrapper = styled.div`
  padding: 24px 16px;
  background-color: #f1f1f4;

  p {
    margin-top: 0;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;
`

const DoubleColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  /* margin-right: 12px; */
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-basis: 100%; */
  /* flex: 1; */
  /* margin-left: 12px; */
`
const InputName = styled.input.attrs({
  type: 'text'
})`
  border: 0;
  padding: 12px;
  font-size: 1rem;
  width: 100%;
`

// const InputValue = styled.input.attrs({
//   type: 'number',
// })`
//   border: 0;
//   padding: 12px;
//   text-align: center;
//   font-size: 1rem;
// `;

const InputUnit = styled.input.attrs({
  type: 'text'
})`
  border: 0;
  padding: 10px;
  font-size: 0.9rem;
`

const TextArea = styled.textarea`
  border: 0;
  padding: 12px;
  font-size: 1rem;
  width: 100%;
`
