import React from 'react'
import PropTypes from 'prop-types'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f7f8f9',
    opacity: 0.5,
    transition: 'opacity 0.2s ease-in-out',

    '&:hover': {
      backgroundColor: '#f7f8f9',
      opacity: 0.9
    }
  }
})

const DownloadImage = ({ imageSrc, imageName, show }) => {
  const classes = useStyles()
  const { t } = useTranslation('proposals')

  const downLoadImage = async event => {
    try {
      event.stopPropagation()
      const response = await fetch(imageSrc)
      const blob = await response.blob()
      const imageUrl = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = imageUrl
      a.download = `${imageName}.jpeg`
      a.click()
    } catch (error) {}
  }

  return (
    show && (
      <Tooltip title={t('Download')}>
        <IconButton className={classes.root} onClick={downLoadImage}>
          <CloudDownloadIcon color="action" />
        </IconButton>
      </Tooltip>
    )
  )
}

DownloadImage.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageName: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired
}

export default DownloadImage
