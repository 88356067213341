import * as React from 'react'
import styled from 'styled-components'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import _ from 'lodash'

import useFirestore from './hooks/useFirestore'
import ToggleSwitch from './components/ToggleSwitch'

export default function ItemDescription({ items, setItems, item }) {
  const [showDialog, setShowDialog] = React.useState(false)
  const [editor, setEditor] = React.useState(EditorState.createEmpty())
  const { updateDocument } = useFirestore('info')

  React.useEffect(() => {
    let editorDetails
    if (item.description) {
      const contentBlock = htmlToDraft(item.description)
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      editorDetails = EditorState.createWithContent(contentState)
    } else {
      editorDetails = EditorState.createEmpty()
    }
    setEditor(editorDetails)
  }, [item.description])

  const onEditorChange = editorState => {
    setEditor(editorState)
  }

  const onSaveHandler = () => {
    const htmlDetails = draftToHtml(convertToRaw(editor.getCurrentContent()))

    handleSaveDescription(htmlDetails)
  }

  const handleSaveDescription = htmlDetails => {
    const hasText = editor.getCurrentContent().hasText()
    const newItems = items.map(currItem => {
      if (currItem.id === item.id) {
        const updatedItem = {
          ...currItem,
          description: hasText ? htmlDetails : ''
        }
        return updatedItem
      }
      return currItem
    })
    const isEqual = _.isEqual(item.description, htmlDetails)

    setItems(newItems)
    !isEqual && updateDocument('submission', { items: newItems })
  }

  const handleToggleShowDescription = itemId => {
    const newItems = items.map(itemElement => {
      if (itemElement.id === itemId) {
        const updatedItem = {
          ...itemElement,
          showDescription: !itemElement.showDescription
        }
        return updatedItem
      }
      return itemElement
    })

    updateDocument('submission', { items: newItems })
    setItems(newItems)
  }

  const open = () => {
    setShowDialog(true)
  }

  const close = () => {
    onSaveHandler()
    setShowDialog(false)
  }

  return (
    <>
      <Description onClick={open}>Edit description</Description>
      <Dialog
        isOpen={showDialog}
        onDismiss={close}
        aria-label="Edit description"
        style={{ minHeight: '70vh' }}
      >
        <TitleWrapper>
          <h2>{item.name}</h2>
          <div>
            <h3>Show description</h3>
            <ToggleSwitch
              itemId={`${item.id}_showDescription`}
              checked={item.showDescription}
              onChange={() => handleToggleShowDescription(item.id)}
            />
          </div>
        </TitleWrapper>
        <h3>Edit description</h3>
        <div className="item-description-container">
          <Editor
            editorState={editor}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class editor_container"
            toolbar={{
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              fontSize: {
                options: [
                  1,
                  2,
                  3,
                  4,
                  5,
                  6,
                  7,
                  8,
                  9,
                  10,
                  11,
                  12,
                  14,
                  16,
                  18,
                  24,
                  30,
                  36,
                  48,
                  60,
                  72,
                  96
                ]
              }
            }}
            onEditorStateChange={onEditorChange}
          />
        </div>
      </Dialog>
    </>
  )
}

const Description = styled.div`
  color: #3f51b5;
  background-color: #fff;
  font-size: 0.7rem;
  cursor: pointer;
  margin-top: 12px;
  padding: 5px 10px;
  border: 1px solid #3f51b5;
  border-radius: 20px;
  transition: all 0.2s ease;

  &:hover {
    background-color: #3f51b5;
    color: #fff;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  h3 {
    margin: 0;
    font-size: 14px;
  }
`
