import React from 'react'
import styled from 'styled-components'

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1090;

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }
`

export const ModalMain = styled.section`
  position: fixed;
  background-color: #f7f8f9;
  width: ${({ modalWidth }) => (modalWidth ? `${modalWidth}px` : '70%')};
  height: ${({ modalHeight }) => (modalHeight ? `${modalHeight}px` : '700px')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1091;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

const CustomModal = ({
  handleClose,
  show,
  children,
  modalWidth,
  modalHeight
}) => {
  const showHideClassName = show ? 'display-block' : 'display-none'

  return (
    show && (
      <>
        <BackDrop className={showHideClassName} onClick={handleClose} />
        <ModalMain modalWidth={modalWidth} modalHeight={modalHeight}>
          {children}
        </ModalMain>
      </>
    )
  )
}

export default CustomModal
