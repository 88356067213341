import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { required, email } from 'redux-form-validators'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import Button from '../inputs/Button'
import TextField from '../inputs/Text'
import Select from '../inputs/Select'
import { addToDataBase, updateDataBase } from '../redux/actions/dataBase'
import {
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail
} from '../redux/actions/authActions'

const options = [
  {
    label: 'Judge',
    value: 'judge'
  },
  {
    label: 'Secretary',
    value: 'secretary'
  },
  {
    label: 'Specialist',
    value: 'specialist'
  },
  {
    label: 'Admin',
    value: 'admin'
  }
]

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    backgroundColor: theme.modeColors.inputBackground,
    width: '500px'
  },
  dialogTitle: {
    borderBottom: '1px solid #e0e2e4',
    margin: 0,
    padding: '20px 16px 18px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#353c43'
  }
})

function UserForm({
  userData,
  handleSubmit,
  closeModal,
  initialize,
  updateDataBase,
  createUserWithEmailAndPassword,
  enqueueSnackbar,
  addToDataBase,
  sendSignInLinkToEmail,
  deletedUsers
}) {
  const [user, setUser] = React.useState(() => {
    if (userData) {
      return userData
    }
    return {
      firstName: '',
      lastName: '',
      email: '',
      proffesion: '',
      role: 'judge'
    }
  })

  React.useEffect(() => {
    if (userData) {
      // initialize redux-form data
      initialize(userData)
    }
  }, [userData, initialize])

  const handleChange = event => {
    setUser({ ...user, [event.target.name]: event.target.value })
  }

  const handleChangeRole = event => {
    setUser({ ...user, role: event.target.value })
  }

  const handleInvitation = values => {
    const demoPassword = Math.random()
      .toString(36)
      .substr(2, 9)
    const role = user.role
    const initials = `${values.firstName}.${values.lastName}`
    if (userData) {
      const data = { ...values, initials, role }
      updateDataBase('users', userData.id, data).then(() => {
        closeModal()
      })
    } else {
      const data = { ...values, initials, role, demoPassword, newUser: true }
      createUserWithEmailAndPassword(values.email, demoPassword)
        .then(resp => {
          // add the user details to Firestore
          enqueueSnackbar(`Account created for ${values.email}`, {
            variant: 'success'
          })

          addToDataBase('users', resp.user.uid, data)
            .then(() => {
              // Everything ok start send invitation to the email
              enqueueSnackbar('User data saved', {
                variant: 'success'
              })

              sendSignInLinkToEmail(values.email)
                .then(() => {
                  // invitation sent
                  enqueueSnackbar('Invitation sent', {
                    variant: 'success'
                  })
                })
                .catch(err => {
                  //didn't sent the invitation
                  enqueueSnackbar(err.message, {
                    variant: 'warning'
                  })
                })
            })
            .catch(err => {
              //Error during save data on Firestore
              enqueueSnackbar(err.message, { variant: 'warning' })
            })
        })
        .catch(err => {
          //account already available update only the details
          enqueueSnackbar(err.message, { variant: 'warning' })
          deletedUsers.map(user => {
            if (user.email === values.email) {
              addToDataBase('users', user.id, data)
                .then(() => {
                  // Everything ok start send invitation to the email
                  enqueueSnackbar('User data saved', {
                    variant: 'success'
                  })

                  sendSignInLinkToEmail(values.email)
                    .then(() => {
                      // invitation sent
                      enqueueSnackbar('Invitation sent', {
                        variant: 'success'
                      })
                    })
                    .catch(err => {
                      //didn't sent the invitation
                      enqueueSnackbar(err.message, {
                        variant: 'warning'
                      })
                    })
                })
                .catch(err => {
                  //Error during save data on Firestore
                  enqueueSnackbar(err.message, {
                    variant: 'warning'
                  })
                })
            }
            return null
          })
        })
      closeModal()
    }
  }

  return (
    <form onSubmit={handleSubmit(handleInvitation)}>
      <div className="px-3 pt-2 pb-0">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={4}>
            <div className="sm text-md-right">Name</div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              id="firstName"
              name="firstName"
              onChange={handleChange}
              size="md"
              placeholder="Input"
              validate={[required()]}
              validation
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={4}>
            <div className="sm text-md-right">Surname</div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              id="lastName"
              name="lastName"
              onChange={handleChange}
              size="md"
              placeholder="Input"
              validate={[required()]}
              validation
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={4}>
            <div className="sm text-md-right">Email</div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              disabled={!!userData}
              id="email"
              name="email"
              onChange={handleChange}
              size="md"
              placeholder="Input"
              validate={[email()]}
              validation
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={4}>
            <div className="sm text-md-right">Profession</div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              id="proffesion"
              name="proffesion"
              onChange={handleChange}
              size="md"
              placeholder="Input"
              validate={[required()]}
              validation
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={4}>
            <div className="sm text-md-right">Rate factor</div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              id="rateFactor"
              name="rateFactor"
              onChange={handleChange}
              size="md"
              placeholder="0"
              validation
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={4}>
            <div className="sm text-md-right">Role</div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              onChange={handleChangeRole}
              value={user.role}
              options={options}
            />
          </Grid>
        </Grid>
      </div>
      <div className="d-flex align-items-center justify-content-end pt-1 m-3">
        <Button
          size="md"
          variant="outlined"
          onClick={closeModal}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          style={{ marginLeft: '8px' }}
          type="submit"
          size="md"
          variant="contained"
          color="primary"
        >
          {userData ? 'Update' : 'Send invitation'}
        </Button>
      </div>
    </form>
  )
}

const ManageUserForm = reduxForm({
  form: 'ManageUserForm' // a unique identifier for this form
})(withStyles(styles)(UserForm))

const mapDispatchToProps = dispatch => {
  return {
    updateDataBase: (coll, doc, data) =>
      dispatch(updateDataBase(coll, doc, data)),
    addToDataBase: (coll, doc, data) =>
      dispatch(addToDataBase(coll, doc, data)),
    createUserWithEmailAndPassword: (email, pass) =>
      dispatch(createUserWithEmailAndPassword(email, pass)),
    sendSignInLinkToEmail: email => dispatch(sendSignInLinkToEmail(email))
  }
}

const mapStateToProps = state => {
  return {
    deletedUsers: state.firestore.ordered.deletedUsers
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'deletedUsers' }])
)(withSnackbar(withStyles(styles)(ManageUserForm)))
