import React from 'react'
import {
  MdConstruction,
  MdEco,
  MdInsertChart,
  MdLocalActivity,
  MdOutlineArchitecture,
  MdOutlineBedroomChild,
  MdOutlineEnergySavingsLeaf,
  MdRoute
} from 'react-icons/md'
import { GiHealthIncrease, GiModernCity } from 'react-icons/gi'
import { FaBorderNone, FaPeopleGroup } from 'react-icons/fa6'
import { TbKeyframes, TbNurse, TbTimeDuration30 } from 'react-icons/tb'
import { BiTrafficCone } from 'react-icons/bi'
import { LuParkingCircle } from 'react-icons/lu'
import { GoWorkflow } from 'react-icons/go'
import {
  BsBarChartSteps,
  BsBuildingGear,
  BsFillPeopleFill
} from 'react-icons/bs'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { GrCheckbox } from 'react-icons/gr'
import { PiTrafficSignFill } from 'react-icons/pi'
import { FaParking } from 'react-icons/fa'

export const rating_fields = [
  {
    name: 'judge_weighted_average_score',
    label: `Total`,
    svg: <MdInsertChart />
  },
  {
    name: 'arkkitehtoninen_laatu',
    label: 'Arkkitehtoninen laatu',
    svg: <MdOutlineArchitecture />
  },
  {
    name: 'ratkaisun_omaperäisyys',
    label: 'Ratkaisun omaperäisyys',
    svg: <HiOutlineLightBulb />
  },
  {
    name: 'kaupunkikuvallinen_laatu',
    label: 'Kaupunkikuvallinen laatu',
    svg: <GiModernCity />
  },
  {
    name: 'uuden_ja_vanhan_yhdistäminen',
    label: 'Uuden ja vanhan yhdistäminen',
    svg: <MdConstruction />
  },
  {
    name: 'tilojen_muunneltavuus_ja_monikäyttöisyys',
    label: 'Tilojen muunneltavuus ja monikäyttöisyys',
    svg: <BsBarChartSteps />
  },
  {
    name: 'näyttämö_ja_katsomotilat',
    label: 'Näyttämö- ja katsomotilat',
    svg: <GrCheckbox />
  },
  {
    name: 'tekniset_tilat',
    label: 'Tekniset tilat',
    svg: <MdLocalActivity />
  },
  {
    name: 'yleisötilat',
    label: 'Yleisötilat',
    svg: <FaPeopleGroup />
  },
  {
    name: 'henkilökunnan_tilat',
    label: 'Henkilökunnan tilat',
    svg: <BsFillPeopleFill />
  },
  {
    name: 'logistiikka_ja_liikenneratkaisut',
    label: 'Logistiikka ja liikenneratkaisut',
    svg: <PiTrafficSignFill />
  },
  {
    name: 'pysäköinnin_ratkaisut',
    label: 'Pysäköinnin ratkaisut',
    svg: <FaParking />
  },
  {
    name: 'toimintojen_sijoittuminen_tontilla_ja_ulkotilat',
    label: 'Toimintojen sijoittuminen tontilla ja ulkotilat',
    svg: <FaBorderNone />
  },
  {
    name:
      'vähähiilisyys_ympäristö_ja_energiatehokkuus_kestävyysnäkökulmat_kiertotalous',
    label:
      'Vähähiilisyys, ympäristö. ja energiatehokkuus, kestävyysnäkökulmat, kiertotalous',
    svg: <MdEco />
  },
  {
    name: 'teknistaloudellinen_toteutettavuus',
    label: 'Teknistaloudellinen toteutettavuus',
    svg: <MdInsertChart />
  }
]

export const rating_fields_for_specialist = [
  {
    name: 'expert_weighted_average_score',
    label: `Total`,
    svg: <MdInsertChart />
  },
  {
    name: 'tiimi_1_toimintojen_sijoittuminen_ja_yhteydet',
    label: 'tiimi 1 Toimintojen sijoittuminen ja yhteydet',
    svg: <MdRoute />
  },
  {
    name: 'tiimi_1_osastojen_toiminta_hoitohenkilökunnan_näkökulmasta',
    label: 'tiimi 1 Osastojen toiminta hoitohenkilökunnan näkökulmasta',
    svg: <TbNurse />
  },
  {
    name: 'tiimi_1_osastomoduuli_ja_potilashuone',
    label: 'tiimi 1 Osastomoduuli ja potilashuone',
    svg: <MdOutlineBedroomChild />
  },
  {
    name: 'tiimi_1_kuntoutumisen_mahdollisuudet',
    label: 'tiimi 1 Kuntoutumisen mahdollisuudet',
    svg: <GiHealthIncrease />
  },
  {
    name: 'tiimi_2_hälytys-,_henkilö-_ja_huoltoliikenteen_sujuvuus',
    label: 'tiimi 2 Hälytys-, henkilö- ja huoltoliikenteen sujuvuus',
    svg: <BiTrafficCone />
  },
  {
    name: 'tiimi_2_ajoneuvo-_ja_polkupyöräpysäköinti',
    label: 'tiimi 2 Ajoneuvo- ja polkupyöräpysäköinti',
    svg: <LuParkingCircle />
  },
  {
    name: 'tiimi_2_sisälogistiikan_henkilö-_ja_materiaalivirrat',
    label: 'tiimi 2 Sisälogistiikan henkilö- ja materiaalivirrat',
    svg: <GoWorkflow />
  },
  {
    name: 'tiimi_2_rakennuksen_ylläpito',
    label: 'tiimi 2 Rakennuksen ylläpito',
    svg: <BsBuildingGear />
  },
  {
    name: 'tiimi_3_toteutettavuus_ja_pitkäaikaiskestävyys',
    label: 'tiimi 3 Toteutettavuus ja pitkäaikaiskestävyys',
    svg: <TbTimeDuration30 />
  },
  {
    name: 'tiimi_3_runko-_ja_taloteknisten_järjestelmien_johdonmukaisuus',
    label: 'tiimi 3 Runko- ja taloteknisten järjestelmien johdonmukaisuus',
    svg: <TbKeyframes />
  },
  {
    name: 'tiimi_3_energiatehokkuus',
    label: 'tiimi 3 Energiatehokkuus',
    svg: <MdOutlineEnergySavingsLeaf />
  }
]
