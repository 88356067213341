import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { filterProposals } from '../../redux/actions/proposalsActions'
import * as searchHelper from '../../util/searchHelper'
import MultipleSelect from '../../inputs/MultipleSelect'
import { useTranslation } from 'react-i18next'

const PublicTags = ({
  filterProposals,
  history,
  PublicTags,
  proposals,
  selectedPhase
}) => {
  const [name, setName] = React.useState([])
  const [publicTagFilterBy, setPublicTagFilterBy] = React.useState(null)
  const { t } = useTranslation('navbar')

  React.useEffect(() => {
    const { search } = history.location
    if (!search) setName([])
    const searchQuery = searchHelper.getSearchQuery(search)

    if (searchQuery.public_tags) {
      const publicTags = searchQuery.public_tags.split(',')
      setName(publicTags)
    }
  }, [history.location])

  const handleChange = event => {
    const { value } = event.target

    if (value[value.length - 1] !== 'none') setName(value)
    console.log('SEARCH VALUES', name)
  }

  const selectAll = publicTags => {
    const values = publicTags.map(publicTag => publicTag.name)

    setName(values)
  }

  const clearAll = () => setName([])

  const applyFilters = () => {
    history.replace({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'public_tags',
        name
      )
    })

    filterProposals(history.location.search, publicTagFilterBy)
    // filterProposals(history.location.search);
  }

  const publicTags = () => {
    // const firebaseDataByPhase = _.filter(
    //   proposals,
    //   proposal => proposal.phaseId === selectedPhase
    // );
    const tags = PublicTags
      ? PublicTags.map(val => {
          const proposalsHaveTags = _.filter(
            proposals,
            proposal => proposal.tags
          )
          const proposalsTags = _.map(proposalsHaveTags, 'tags')
          const allTags = [].concat.apply([], proposalsTags)
          const amount = _.filter(allTags, ['id', val.id])

          return {
            name: val.name,
            amount: amount.length
          }
        })
      : []

    const tagsInUse = tags.filter(tag => tag.amount > 0)

    return tagsInUse
  }

  return (
    <div id="filter-public-tags">
      <MultipleSelect
        options={publicTags()}
        values={name}
        onChange={handleChange}
        selectAll={() => selectAll(publicTags())}
        clearAll={clearAll}
        placeholder={t('Jury tags')}
        apply={applyFilters}
        publicTagFilterBy={publicTagFilterBy}
        setPublicTagFilterBy={setPublicTagFilterBy}
      />
    </div>
  )
}

PublicTags.propTypes = {
  filterProposals: PropTypes.func.isRequired,
  selectedPhase: PropTypes.string.isRequired,
  proposals: PropTypes.arrayOf(PropTypes.object),
  PublicTags: PropTypes.arrayOf(PropTypes.object)
}

const mapStateToProps = state => {
  return {
    PublicTags: state.firestore.ordered.PublicTags,
    proposals: state.firestore.ordered.proposals,
    selectedPhase: state.proposals.selectedPhase
  }
}

export default compose(
  connect(mapStateToProps, { filterProposals }),
  firestoreConnect([{ collection: 'PublicTags' }, { collection: 'proposals' }])
)(withRouter(PublicTags))
