import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import Input from './components/Input'
import useCollection from './hooks/useCollection'
import useFirestore from './hooks/useFirestore'
import SubmitPageStatus from './SubmitPageStatus'

export default function Info() {
  const { updateDocument } = useFirestore('info')
  const { documents } = useCollection('info')
  const { t } = useTranslation('proposals')

  const handleUpdateInfo = updateData => {
    updateDocument('general', updateData)
  }

  if (!documents) {
    return <div />
  }

  return (
    <div>
      <SubmitPageStatus status={documents?.general?.submitPageStatus} />

      <div style={{ marginTop: 16 }}>
        <Input
          inputType="text"
          label={`${t('Competition name')}: `}
          inputId="competition-name"
          inputName="competition-name"
          handleSave={value => handleUpdateInfo({ name: value })}
          inputValue={documents?.general?.name}
        />
      </div>

      <div style={{ marginTop: 16 }}>
        <Input
          inputType="text"
          label={`${t('Competition page link')} - EN: `}
          inputId="competition-page-link-en"
          inputName="competition-page-link-en"
          handleSave={value => handleUpdateInfo({ webFlowLink: value })}
          inputValue={documents?.general?.webFlowLink || 'https://tietoa.fi/'}
        />
      </div>

      <div style={{ marginTop: 16 }}>
        <Input
          inputType="text"
          label={`${t('Competition page link')} - FI: `}
          inputId="competition-page-link-fi"
          inputName="competition-page-link-fi"
          handleSave={value => handleUpdateInfo({ webFlowLinkFI: value })}
          inputValue={documents?.general?.webFlowLinkFI || 'https://tietoa.fi/'}
        />
      </div>

      <div style={{ marginTop: 16 }}>
        <Input
          inputType="text"
          label={`${t('Competition page link')} - SE: `}
          inputId="competition-page-link-se"
          inputName="competition-page-link-se"
          handleSave={value => handleUpdateInfo({ webFlowLinkSE: value })}
          inputValue={documents?.general?.webFlowLinkSE || 'https://tietoa.fi/'}
        />
      </div>

      <div style={{ marginTop: 24 }}>
        <OpeningInput openingDate={documents?.general?.openingDate} t={t} />
      </div>

      <div style={{ marginTop: 24 }}>
        <DeadlineInput deadline={documents?.general?.deadline} t={t} />
      </div>
    </div>
  )
}

function OpeningInput({ t, openingDate }) {
  const formattedDate = openingDate ? openingDate.toDate() : ''
  const [date, setDate] = React.useState(formattedDate)
  const { updateDocument } = useFirestore('info')

  const handleChange = date => {
    setDate(date)
    updateDocument('general', { openingDate: date })
  }

  return (
    <Label htmlFor="submission-open-date">
      <span style={{ minWidth: 200, marginRight: 24 }}>
        {t('Submission opening date')}:
      </span>

      <DatePicker
        wrapperClassName="date-picker"
        selected={formattedDate ? formattedDate : date}
        onChange={date => handleChange(date)}
        showTimeSelect
        dateFormat="dd MMMM, yyyy - h:mm aa"
      />
    </Label>
  )
}

function DeadlineInput({ t, deadline }) {
  const formattedDate = deadline ? deadline.toDate() : ''
  const [date, setDate] = React.useState(formattedDate)
  const { updateDocument } = useFirestore('info')

  const handleChange = date => {
    setDate(date)
    updateDocument('general', { deadline: date })
  }

  return (
    <Label htmlFor="submission-deadline">
      <span style={{ minWidth: 200, marginRight: 24 }}>
        {t('Submission deadline')}:
      </span>

      <DatePicker
        wrapperClassName="date-picker"
        selected={formattedDate ? formattedDate : date}
        onChange={date => handleChange(date)}
        showTimeSelect
        dateFormat="dd MMMM, yyyy - h:mm aa"
      />
    </Label>
  )
}

const Label = styled.label`
  display: flex;
  align-items: center;

  .react-datepicker__input-container input {
    border: 1px solid #f1f1f4;
    padding: 12px;
    font-size: 1rem;
    min-width: 300px;
  }
`
