import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import { styled } from '@material-ui/styles'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { Collapse, Chip } from '@material-ui/core'
import { BiSolidLike } from 'react-icons/bi'

import { renderScopeDataView } from '../single-proposal/ElementTabs'
import { useTranslation } from 'react-i18next'
import useShortlistedProposals from '../hooks/useShortlistedProposals'

const ListItem = ({
  proposal,
  expandAll,
  classes,
  proposalClass,
  proposalPublicTags,
  proposalPrivateTags,
  proposalPublicNote,
  proposalPrivateNote,
  proposalPublicRate,
  proposalsOrdered,
  PublicClasses,
  id,
  users,
  reactAppUrl,
  selectedPhase
}) => {
  const [expand, setExpand] = React.useState(false)
  const { isProposalShortlisted } = useShortlistedProposals()
  const { t } = useTranslation('proposals')

  const toggleExpand = () => {
    setExpand(previousExpand => !previousExpand)
  }

  React.useEffect(() => {
    expandAll ? setExpand(true) : setExpand(false)
  }, [expandAll])

  const renderProposalName = () => {
    if (isProposalShortlisted(proposal.proposalId)) {
      return (
        <div className={`col xs ${classes.shortListedPseudonym}`}>
          <BiSolidLike />
          <span style={{ paddingTop: 3 }}>{proposal.name}</span>
        </div>
      )
    }
    return <div className={`col xs ${classes.pseudonym}`}>{proposal.name}</div>
  }

  return (
    <div className={`border-bottom py-2 ${expand ? classes.expandItem : ''}`}>
      <div className="d-flex align-items-start">
        <div className={classes.toggle}>
          {!expand ? (
            <ExpandMore onClick={toggleExpand} />
          ) : (
            <ExpandLess onClick={toggleExpand} />
          )}
        </div>
        <div className={classes.no}>{proposal.number}</div>
        {renderProposalName()}
        <div className={classes.class}>
          {proposalClass ? (
            <div
              className={classes.classBox}
              style={{ backgroundColor: proposalClass.colour }}
            >
              {proposalClass.class}
            </div>
          ) : (
            <div className={classes.classHolder}> {t('No class')} </div>
          )}
        </div>
        <div className="col sm">
          {proposalPublicTags.map((tag, i) => {
            return (
              <Chip
                key={tag.id}
                label={tag.name}
                classes={{
                  root: classes.chipTag,
                  label: 'xs p-0'
                }}
              />
            )
          })}
        </div>
        <div
          className={
            !expand && proposalPublicNote ? 'ellipsis-text col xs' : 'col xs'
          }
        >
          {proposalPublicNote}
        </div>
        <div className="col sm">
          {proposalPrivateTags.map((tag, i) => {
            return (
              <Chip
                key={tag.id}
                label={tag.name}
                classes={{
                  root: classes.chipTag,
                  label: 'xs p-0'
                }}
              />
            )
          })}
        </div>
        <div className="col xs">{proposalPrivateNote}</div>
      </div>
      <Collapse in={expand}>
        <div className="border-top mt-1 d-flex justify-content-start pt-1 pl-1">
          {expand && (
            <div className={classes.imageContainer}>
              <Link className="normal-text" to={`/proposal/${id}`}>
                <img
                  className="img-fluid"
                  src={reactAppUrl + proposal.thumbnail_url}
                  alt={proposal.name}
                />
              </Link>
            </div>
          )}

          <div className={classes.keysContainer}>
            <StyledH5>{t('Key figures of the entry')}</StyledH5>
            {/* Only show data when expand to reduce loading time */}
            {expand && renderScopeDataView(proposal, t)}
          </div>
        </div>
      </Collapse>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    reactAppUrl: state.proposals.envValues.reactAppUrl,
    selectedPhase: state.proposals.selectedPhase
  }
}

const styles = theme => ({
  toggle: {
    width: '2rem',
    fontSize: '0.875rem'
  },
  no: {
    width: '3rem',
    fontWeight: 700,
    fontSize: '1.375rem',
    color: theme.modeColors.textColor
  },
  pseudonym: {
    maxWidth: '7rem !important',
    fontWeight: 400,
    fontSize: '0.875rem',
    paddingTop: 5,
    paddingRight: 8,
    marginRight: 10,
    hyphens: 'auto',
    wordBreak: 'break-word'
  },
  shortListedPseudonym: {
    backgroundColor: '#f93838',
    color: '#fff',
    maxWidth: '7rem !important',
    fontWeight: 400,
    fontSize: '0.875rem',
    marginRight: 10,
    padding: '5px 4px',
    hyphens: 'auto',
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8
  },
  class: {
    width: '7.5rem',
    fontWeight: 700,
    fontSize: '0.75rem'
  },
  classBox: {
    padding: '6px 4px 4px',
    width: '6.5rem',
    textAlign: 'center',
    color: '#fff'
  },
  classHolder: {
    paddingTop: 5
  },
  chipTag: {
    height: '1.5rem',
    backgroundColor: '#fff',
    padding: '0.25rem 0.75rem 0.125rem',
    marginLeft: '0.375rem',
    marginBottom: '0.25rem',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
    fontWeight: 400,
    fontSize: '0.75rem'
  },
  imageContainer: {
    width: '10rem',
    marginRight: '2rem'
  },
  keysContainer: {
    width: '20rem',
    marginRight: '2rem'
  },
  juryRating: {
    marginRight: '2rem'
  },
  publicReview: {
    flex: 1,
    marginRight: '2rem',
    fontSize: '0.75rem'
  },
  expandItem: {
    backgroundColor: theme.modeColors.expandItemBackground
  }
})

const StyledH5 = styled('h5')(({ theme }) => ({
  color: theme.modeColors.singleProposalSideNavText
}))

export default connect(mapStateToProps)(withStyles(styles)(ListItem))

// function PrintButtons({
//   pdfData,
//   proposalsOrdered,
//   PublicClasses,
//   users,
//   reactAppUrl,
//   selectedPhase,
//   proposal,
// }) {
//   const { t, i18n } = useTranslation();
//   const [language, setLanguage] = React.useState('en');
//   const printButtonsData = [
//     {
//       id: 'onePageNoProfile',
//       label: t('PDF - 1 page - no profile'),
//       isOnePagePdf: true,
//       hasProfile: false,
//     },
//     {
//       id: 'onePageWithProfile',
//       label: t('PDF - 1 page - with profile'),
//       isOnePagePdf: true,
//       hasProfile: true,
//     },
//     {
//       id: 'twoPageNoProfile',
//       label: t('PDF - 2 page - no profile'),
//       isOnePagePdf: false,
//       hasProfile: false,
//     },
//     {
//       id: 'twoPageWithProfile',
//       label: t('PDF - 2 page - with profile'),
//       isOnePagePdf: false,
//       hasProfile: true,
//     },
//   ];
//   const [printBtnState, setPrintBtnState] = React.useState({
//     onePageNoProfile: false,
//     onePageWithProfile: false,
//     twoPageNoProfile: false,
//     twoPageWithProfile: false,
//   });

//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       alignItems="flex-end"
//       justifySelf="flex-end"
//     >
//       {/* Language switchers */}
//       <div className="d-flex align-items-center">
//         {/* <StyledH5 className="mb-0 mr-2">Print PDF in: </StyledH5> */}
//         <div className="pr-1">
//           <button
//             type="button"
//             className={`lang-btn ${i18n.language === 'en' ? 'active' : ''}`}
//             style={{ marginRight: '5px' }}
//             onClick={() => {
//               i18n.changeLanguage('en');
//               setLanguage('en');
//             }}
//           >
//             <img className="lang-img" src={FlagEN} alt="English" />
//           </button>
//           <button
//             type="button"
//             className={`lang-btn ${i18n.language === 'fi' ? 'active' : ''}`}
//             onClick={() => {
//               i18n.changeLanguage('fi');
//               setLanguage('fi');
//             }}
//           >
//             <img className="lang-img" src={FlagFI} alt="Suomi" />
//           </button>
//         </div>
//       </div>

//       {printButtonsData.map(({ id, label, isOnePagePdf, hasProfile }) => (
//         <div className="pr-1 pt-1" key={id}>
//           {!printBtnState[id] && (
//             <Button
//               size="sm"
//               variant="contained"
//               color="primary"
//               onClick={() => setPrintBtnState({ [id]: true })}
//               style={{ width: '14rem' }}
//             >
//               {t('load_pdf', { label: label })}
//             </Button>
//           )}

//           {printBtnState[id] && (
//             <PDFDownloadLink
//               document={
//                 <ExportPdf
//                   data={pdfData}
//                   proposalsOrdered={proposalsOrdered}
//                   PublicClasses={PublicClasses}
//                   users={users}
//                   isOnePagePdf={isOnePagePdf}
//                   reactAppUrl={reactAppUrl}
//                   selectedPhase={selectedPhase}
//                   hasProfile={hasProfile}
//                 />
//               }
//               fileName={`${proposal.number} - ${proposal.name} - ${label}.pdf`}
//             >
//               {({ blob, url, loading, error }) => (
//                 <Button
//                   size="sm"
//                   variant="contained"
//                   color="primary"
//                   style={{ width: '14rem' }}
//                 >
//                   {loading ? t('Loading document...') : t('export_pdf', { label: label })}
//                 </Button>
//               )}
//             </PDFDownloadLink>
//           )}
//         </div>
//       ))}
//     </Box>
//   );
// }
