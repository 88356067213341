import React from 'react'

import { projectFirestore } from '../../../util/fbconfig'

export default function useCollection(
  collection,
  queryKey = '',
  queryValue = '',
  dependency = false
) {
  const [documents, setDocuments] = React.useState(null)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    // console.log('useCollection triggered with dependency:', dependency)

    // Reset state when dependencies change
    setDocuments(null)
    setError(null)

    let ref =
      !!queryKey && queryValue
        ? projectFirestore
            .collection(collection)
            .where(queryKey, '==', queryValue)
        : projectFirestore.collection(collection)

    const unsubscribe = ref.onSnapshot(
      snapshot => {
        if (snapshot.empty) {
          setError('No data')
          setDocuments(null)
        } else {
          let results = {}
          snapshot.docs.forEach(doc => {
            results[doc.id] = doc.data()
          })
          setDocuments(results)
        }
      },
      error => {
        setError(error.message)
        setDocuments(null)
      }
    )

    return () => unsubscribe()
  }, [collection, queryKey, queryValue, dependency])

  return { documents, error }
}
