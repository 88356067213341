import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'

import * as searchHelper from '../../util/searchHelper'
import { filterProposals } from '../../redux/actions/proposalsActions'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {
    color: theme.modeColors.checkbox
  },
  label: {
    fontFamily: 'HarmoniaSansStd',
    paddingTop: 4,
    color: theme.modeColors.checkboxLabel,
    fontSize: 14
  }
})

const HasNotes = ({ filterProposals, history, classes }) => {
  const [checkedF, setCheckedF] = useState(false)
  const { t } = useTranslation('navbar')

  useEffect(() => {
    const { search } = history.location
    const searchQuery = searchHelper.getSearchQuery(search)

    if (searchQuery.has_notes) {
      setCheckedF(!!searchQuery.has_notes)
    }
  }, [history.location])

  const handleChange = event => {
    setCheckedF(event.target.checked)

    history.replace({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'has_notes',
        event.target.checked
      )
    })

    filterProposals(history.location.search)
  }

  return (
    <FormControlLabel
      id="filter-has-notes"
      control={
        <Checkbox
          className={classes.root}
          checked={checkedF}
          onChange={handleChange}
          value="checkedF"
          color="default"
        />
      }
      label={t('Has Notes')}
      classes={{
        label: classes.label
      }}
    />
  )
}

HasNotes.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
  classes: PropTypes.objectOf(Object).isRequired,
  filterProposals: PropTypes.func.isRequired
}

export default withRouter(
  connect(null, { filterProposals })(withStyles(styles)(HasNotes))
)
