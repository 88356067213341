import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Box } from '@material-ui/core'

import { MdInsertChart, MdConstruction, MdEco } from 'react-icons/md'
import { GiStairsGoal, GiModernCity } from 'react-icons/gi'
import { MdOutlineArchitecture, MdLocalActivity } from 'react-icons/md'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { BsFillPeopleFill, BsBarChartSteps } from 'react-icons/bs'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { FaParking, FaBorderNone } from 'react-icons/fa'
import { FaUsersGear, FaPeopleGroup } from 'react-icons/fa6'
import { TbProgressCheck } from 'react-icons/tb'
import { GrCheckbox } from 'react-icons/gr'
import { PiTrafficSignFill } from 'react-icons/pi'

const icons = {
  MdInsertChart,
  GiStairsGoal,
  AiOutlineCheckCircle,
  MdOutlineArchitecture,
  HiOutlineLightBulb,
  BsFillPeopleFill,
  BsBarChartSteps,
  MdLocalActivity,
  GiModernCity,
  MdConstruction,
  FaParking,
  MdEco,
  FaUsersGear,
  TbProgressCheck,
  GrCheckbox,
  FaPeopleGroup,
  PiTrafficSignFill,
  FaBorderNone
}

const iconOptions = [
  {
    label: 'MdInsertChart',
    value: 'MdInsertChart'
  },
  {
    label: 'GiStairsGoal',
    value: 'GiStairsGoal'
  },
  {
    label: 'AiOutlineCheckCircle',
    value: 'AiOutlineCheckCircle'
  },
  {
    label: 'MdOutlineArchitecture',
    value: 'MdOutlineArchitecture'
  },
  {
    label: 'HiOutlineLightBulb',
    value: 'HiOutlineLightBulb'
  },
  {
    label: 'BsFillPeopleFill',
    value: 'BsFillPeopleFill'
  },
  {
    label: 'BsBarChartSteps',
    value: 'BsBarChartSteps'
  },
  {
    label: 'MdLocalActivity',
    value: 'MdLocalActivity'
  },
  {
    label: 'GiModernCity',
    value: 'GiModernCity'
  },
  {
    label: 'MdConstruction',
    value: 'MdConstruction'
  },
  {
    label: 'FaParking',
    value: 'FaParking'
  },
  {
    label: 'MdEco',
    value: 'MdEco'
  },
  {
    label: 'FaUsersGear',
    value: 'FaUsersGear'
  },
  {
    label: 'TbProgressCheck',
    value: 'TbProgressCheck'
  },
  {
    label: 'GrCheckbox',
    value: 'GrCheckbox'
  },
  {
    label: 'FaPeopleGroup',
    value: 'FaPeopleGroup'
  },
  {
    label: 'PiTrafficSignFill',
    value: 'PiTrafficSignFill'
  },
  {
    label: 'FaBorderNone',
    value: 'FaBorderNone'
  }
]

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  menuItem: {
    margin: '4px 8px',
    padding: '4px 8px'
  }
}))

export default function RatingCriteriaIconOptions({
  criteriaItemIcon,
  handleChangeIcon
}) {
  const classes = useStyles()

  const renderIcon = iconName => {
    const Icon = icons[iconName]
    const iconProps = { style: { width: 23, height: 23 } }
    return <Icon {...iconProps} />
  }

  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="select-icon-for-rating-criteria"
        id="select-icon-for-rating-criteria"
        value={criteriaItemIcon}
        onChange={handleChangeIcon}
      >
        {iconOptions.map(option => (
          <MenuItem
            disableGutters={true}
            key={option.value}
            value={option.value}
            classes={{
              root: classes.menuItem
            }}
          >
            <Box display="flex" alignItems="center">
              {renderIcon(option.value)}
              <Box ml={2}>{option.label}</Box>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
