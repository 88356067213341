import axios from 'axios'
import * as searchHelper from '../../util/searchHelper'
import _ from 'lodash'
import {
  filtersData,
  advancedData,
  getProposalAllRate,
  getProposalPublicRate,
  getProposalAllRateForSpecialists,
  getProposalPublicRateForSpecialists
} from '../../util/Helper'

const getFirebaseCollectionAsObject = async (firestore, collection) => {
  try {
    const dataRef = firestore.collection(collection)
    const data = await dataRef.get()
    return _.reduce(
      data.docs,
      (result, doc, key) => {
        const id = doc.id
        const data = doc.data()
        return Object.assign(result, { [id]: data })
      },
      {}
    )
  } catch (error) {
    console.log('Could not get data from firebase:', error)
  }
}

const getFirebaseCollectionAsArray = async (firestore, collection) => {
  try {
    const dataRef = firestore.collection(collection)
    const data = await dataRef.get()
    return data.docs.map(doc => Object.assign({ id: doc.id, ...doc.data() }))
  } catch (error) {
    console.log('Could not get ordered data from firebase:', error)
  }
}

export const chooseSortMethod = (
  sortByValue,
  dataToSort,
  proposalsData,
  classes,
  profile,
  users,
  proposalDataOrdered
) => {
  switch (sortByValue) {
    case 1:
      return sortRandom(dataToSort)
    case 2:
      return sortFirstToLast(dataToSort)
    case 3:
      return sortLastToFirst(dataToSort)
    case 4:
      return sortByUnseenOrSeen(dataToSort, profile, 'unseen')
    case 5:
      return sortByUnseenOrSeen(dataToSort, profile, 'seen')
    case 6:
      return sortedByCategory(proposalsData, dataToSort, classes)
    case 7:
      return sortByJuryRating(dataToSort, users)
    case 8:
      return sortBySecretaryRating(dataToSort, proposalDataOrdered)
    case 9:
      return sortNameAToZ(dataToSort)
    case 10:
      return sortNameZToA(dataToSort)
    case 11:
      return sortBySpecialistRating(dataToSort, users)
    case 12:
      return sortBySecretaryRatingForSpecialists(
        dataToSort,
        proposalDataOrdered
      )
    case 13:
      return shortByShortlisted(dataToSort, profile)
    default:
      return
  }
}

export const getProposals = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState()
    const firebase = getFirebase()
    const firestore = getFirestore()

    dispatch({
      type: 'FETCH_PROPOSALS_LOADING'
    })
    // For firefox, when user open this app the first time, user receives error: FirebaseError missing or insufficient permission. This bug is gone after refresh the page
    // Here is a similar problem: https://github.com/firebase/firebase-js-sdk/issues/1491
    // For Firefox, maybe we need to add conditional check if uid is not null by getting current user uid: firebase.auth().currentUser.getIdToken().then(token => console.log('got token', token))

    // Have to get firebase data from 'users collection this way because it always returns undefined at this early stage of the App.
    const users = await getFirebaseCollectionAsArray(firestore, 'users')
    const proposalsData = await getFirebaseCollectionAsObject(
      firestore,
      'proposals'
    )
    const proposalDataOrdered = await getFirebaseCollectionAsArray(
      firestore,
      'proposals'
    )
    const unOrderedClasses = await getFirebaseCollectionAsArray(
      firestore,
      'PublicClasses'
    )
    const classes = _.orderBy(unOrderedClasses, val => val.position)
    const user = firebase.auth().currentUser
    const profile = users.find(({ id }) => id === user.uid)
    const sortBy = profile?.sortBy || 1

    const dataToFetch =
      state.proposals.selectedPhase === 'phase-1'
        ? process.env.REACT_APP_API_PHASE_1
        : process.env.REACT_APP_API_PHASE_2

    axios
      .get(dataToFetch)
      .then(data => {
        const sortedData = chooseSortMethod(
          sortBy,
          data.data.competition.proposals,
          proposalsData,
          classes,
          profile,
          users,
          proposalDataOrdered
        )

        dispatch({
          type: 'FETCH_PROPOSALS_SUCCESS',
          payload: sortedData
        })
      })
      .catch(err => {
        // console.log('err', err);
        dispatch({
          type: 'FETCH_PROPOSALS_ERROR',
          payload: err
        })
      })
  }
}

export const filterProposals = (link, filterBy = 'any') => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState()
    const profile = state.firebase.profile
    const sortBy = profile.sortBy ? profile.sortBy : 1
    const initData = state.proposals.initData
    const classes = state.firestore.ordered.PublicClasses
    const proposalsData = state.firestore.data.proposals
    const proposalDataOrdered = state.firestore.ordered.proposals
    const users = state.firestore.ordered.users

    const searchQuery = searchHelper.getSearchQuery(link)
    const publicClass = searchQuery.classes
    const privateTags = searchQuery.private_tags
    const publicTags = searchQuery.public_tags
    const hasNotes = searchQuery.has_notes
    const shortlisted = searchQuery.shortlisted
    const show = searchQuery.show
    const search = searchQuery.search
    const advancedSearch = searchQuery.advanced_search

    const sortedData = chooseSortMethod(
      sortBy,
      initData,
      proposalsData,
      classes,
      profile,
      users,
      proposalDataOrdered
    )

    if (link) {
      if (search) {
        const newData = searchHelper.searchData(search, initData)
        const sortedNewData = chooseSortMethod(
          sortBy,
          newData,
          proposalsData,
          classes,
          profile,
          users,
          proposalDataOrdered
        )
        dispatch({
          type: 'SEARCH_PROPOSALS_SUCCESS',
          payload: sortedNewData
        })
      } else {
        if (advancedSearch) {
          let data = filtersData(
            show,
            publicClass,
            privateTags,
            publicTags,
            hasNotes,
            shortlisted,
            sortedData,
            profile,
            proposalsData,
            filterBy
          )

          data =
            _.isEmpty(data) &&
            !publicClass &&
            !privateTags &&
            !publicTags &&
            !hasNotes &&
            !shortlisted &&
            !show
              ? sortedData
              : data
          const newData = advancedData(advancedSearch, data)
          dispatch({
            type: 'ADVANCED_SEARCH_SUCCESS',
            payload: newData
          })
        } else {
          const newData = filtersData(
            show,
            publicClass,
            privateTags,
            publicTags,
            hasNotes,
            shortlisted,
            sortedData,
            profile,
            proposalsData,
            filterBy
          )
          // const sortedNewData = sortedByCategory(proposalsData, newData, classes);
          dispatch({
            type: 'FILTER_PROPOSALS_SUCCESS',
            payload: newData
          })
        }
      }
    } else {
      dispatch({
        type: 'FILTER_PROPOSALS_SUCCESS',
        payload: sortedData
      })
    }
  }
}

/**
 * SORT METHODS
 */

const sortRandom = obj => {
  const objToArr = _.toPairs(obj) || []
  let index = objToArr.length

  while (--index > 0) {
    const randomIndex = Math.floor(Math.random() * (index + 1))
    // swap the elements that are in the selected index and at random index
    ;[objToArr[index], objToArr[randomIndex]] = [
      objToArr[randomIndex],
      objToArr[index]
    ]
  }

  const arrToObj = _.fromPairs(objToArr)
  return arrToObj
}

export const sortFirstToLast = dataToSort =>
  _.fromPairs(_.orderBy(_.toPairs(dataToSort), val => val[1].number))

const sortLastToFirst = dataToSort =>
  _.fromPairs(_.orderBy(_.toPairs(dataToSort), val => -val[1].number))

const sortByUnseenOrSeen = (dataToSort, profile, type) => {
  const { proposals } = profile

  if (proposals) {
    const proposals_seen = _.map(
      _.pickBy(proposals, ({ lastSeen }) => lastSeen),
      (val, key) => key
    )

    return _.fromPairs(
      _.orderBy(_.toPairs(dataToSort), val =>
        type === 'unseen'
          ? proposals_seen.includes(val[0])
          : !proposals_seen.includes(val[0])
      )
    )
  }

  return dataToSort
}

const sortedByCategory = (firebaseData, dataToSort, firebaseClassesData) => {
  let newData = {}
  let results = []

  // sort the proposals with class
  const classesNames = _.map(firebaseClassesData, val => val.class)
  const proposalsClass = _.pickBy(firebaseData, val => val.class)

  const sortedDataWithSameClassNameByNumber = (comparedClassName, data) => {
    let sortedData = []

    _.forEach(proposalsClass, (value, key) => {
      if (value.class.class === comparedClassName && data[key]) {
        sortedData.push([key, data[key]])
      }
    })

    // Ascending order
    return sortedData.sort((a, b) => (a[1].number > b[1].number ? 1 : -1))
  }

  _.forEach(classesNames, (value, index) => {
    const sortedResults = sortedDataWithSameClassNameByNumber(
      classesNames[index],
      dataToSort
    )

    results = [...results, ...sortedResults]
  })

  _.forEach(results, value => (newData[value[0]] = value[1]))

  //add the proposals without classes
  const proposalsClassKey = _.map(newData, (val, key) => key)

  _.forEach(dataToSort, (val, key) => {
    if (!proposalsClassKey.includes(key) && val) {
      newData[key] = val
    }
  })

  return newData
}

const sortByJuryRating = (dataToSort, users) => {
  return _.fromPairs(
    _.orderBy(_.toPairs(dataToSort), val => {
      const proposalId = val[0]
      const proposalAllRate = getProposalAllRate(users, proposalId)
      const proposalOverallRate = proposalAllRate.rate[
        'judge_weighted_average_score'
      ]
        ? proposalAllRate.rate['judge_weighted_average_score']
        : 0

      return proposalOverallRate
    }).reverse()
  )
}

const sortBySpecialistRating = (dataToSort, users) => {
  return _.fromPairs(
    _.orderBy(_.toPairs(dataToSort), val => {
      const proposalId = val[0]
      const proposalAllRate = getProposalAllRateForSpecialists(
        users,
        proposalId
      )
      const proposalOverallRate = proposalAllRate.rate[
        'expert_weighted_average_score'
      ]
        ? proposalAllRate.rate['expert_weighted_average_score']
        : 0

      return proposalOverallRate
    }).reverse()
  )
}

const sortBySecretaryRating = (dataToSort, proposalDataOrdered) => {
  return _.fromPairs(
    _.orderBy(_.toPairs(dataToSort), val => {
      const proposalId = val[0]
      const proposalPublicRate = getProposalPublicRate(
        proposalDataOrdered,
        proposalId
      )
      const proposalOverallRate = proposalPublicRate[
        'judge_weighted_average_score'
      ]
        ? proposalPublicRate['judge_weighted_average_score']
        : 0

      return proposalOverallRate
    }).reverse()
  )
}

const sortBySecretaryRatingForSpecialists = (
  dataToSort,
  proposalDataOrdered
) => {
  return _.fromPairs(
    _.orderBy(_.toPairs(dataToSort), val => {
      const proposalId = val[0]
      const proposalPublicRate = getProposalPublicRateForSpecialists(
        proposalDataOrdered,
        proposalId
      )
      const proposalOverallRate = proposalPublicRate[
        'expert_weighted_average_score'
      ]
        ? proposalPublicRate['expert_weighted_average_score']
        : 0

      return proposalOverallRate
    }).reverse()
  )
}

const sortNameAToZ = dataToSort =>
  _.fromPairs(
    _.orderBy(
      _.toPairs(dataToSort),
      val => val[1].name && val[1].name.toLowerCase()
    )
  )

const sortNameZToA = dataToSort =>
  _.fromPairs(
    _.orderBy(
      _.toPairs(dataToSort),
      val => val[1].name && val[1].name.toLowerCase(),
      ['desc']
    )
  )

const shortByShortlisted = (dataToSort, profile) => {
  if (!profile || !profile.proposals) {
    return dataToSort
  }

  const userProposalsData = Object.entries(profile.proposals)
  const shortlistedProposals = userProposalsData
    .filter(([key, value]) => value.shortlisted)
    .map(([key, value]) => key)

  const shortlisted = []
  const notShortlisted = []
  // Separate the shortlisted proposals from the rest of the proposals
  Object.entries(dataToSort).forEach(([key, value]) => {
    if (shortlistedProposals.length > 0 && shortlistedProposals.includes(key)) {
      shortlisted.push([key, value])
    } else {
      notShortlisted.push([key, value])
    }
  })
  // Concatenate the shortlisted proposals with the rest of the proposals.
  return _.fromPairs([...shortlisted, ...notShortlisted])
}

export const sortProposals = val => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState()
    const proposals = state.proposals
    const profile = state.firebase.profile
    const proposalsData = state.firestore.data.proposals
    const proposalDataOrdered = state.firestore.ordered.proposals
    const classes = state.firestore.ordered.PublicClasses
    let newData
    const dataToSort = proposals.search
      ? proposals.searchProposals
      : proposals.data
    const users = state.firestore.ordered.users

    // sort random
    if (val === 1) {
      newData = sortRandom(dataToSort)
    }

    // sort number first to last
    if (val === 2) {
      newData = sortFirstToLast(dataToSort)
    }

    // sort number last to first
    if (val === 3) {
      newData = sortLastToFirst(dataToSort)
    }

    // sort UnSeen First
    if (val === 4) {
      newData = sortByUnseenOrSeen(dataToSort, profile, 'unseen')
    }

    // sort Seen First
    if (val === 5) {
      newData = sortByUnseenOrSeen(dataToSort, profile, 'seen')
    }

    // sort by Category
    if (val === 6) {
      if (proposalsData) {
        newData = sortedByCategory(proposalsData, dataToSort, classes)
      } else {
        newData = dataToSort
      }
    }

    if (val === 7) {
      newData = sortByJuryRating(dataToSort, users)
    }

    if (val === 11) {
      newData = sortBySpecialistRating(dataToSort, users)
    }

    if (val === 8) {
      newData = sortBySecretaryRating(dataToSort, proposalDataOrdered)
    }

    if (val === 12) {
      newData = sortBySecretaryRatingForSpecialists(
        dataToSort,
        proposalDataOrdered
      )
    }

    if (val === 9) {
      newData = sortNameAToZ(dataToSort)
    }

    if (val === 10) {
      newData = sortNameZToA(dataToSort)
    }

    if (val === 13) {
      newData = shortByShortlisted(dataToSort, profile)
    }

    if (proposals.search) {
      dispatch({
        type: 'SORT_SEARCH_PROPOSALS_SUCCESS',
        payload: newData
      })
    } else {
      dispatch({
        type: 'SORT_ALL_PROPOSALS_SUCCESS',
        payload: newData
      })
    }
  }
}

export const updateCookies = data => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_PROPOSALS_COOKIES',
      payload: data
    })
  }
}

export const setPhaseId = phaseId => dispatch =>
  dispatch({ type: 'SET_SELECTED_PHASE', phaseId })

export const setBaseUrlToGetProposalsData = baseUrl => dispatch =>
  dispatch({ type: 'SET_BASE_URL', baseUrl })

export const setViewMode = viewMode => dispatch =>
  dispatch({ type: 'SET_VIEW_MODE', viewMode })

export const setActiveView = activeView => dispatch =>
  dispatch({ type: 'SET_ACTIVE_VIEW', activeView })
