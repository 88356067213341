import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { required } from 'redux-form-validators'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import Button from '../inputs/Button'
import TextField from '../inputs/Text'
import { addToDataBase, updateDataBase } from '../redux/actions/dataBase'
import useFirestore from '../competition-info/submission/hooks/useFirestore'
import RatingCriteriaIconOptions from './RatingCriteriaIconOptions'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    backgroundColor: theme.modeColors.inputBackground,
    width: '500px'
  },
  dialogTitle: {
    borderBottom: '1px solid #e0e2e4',
    margin: 0,
    padding: '20px 16px 18px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#353c43'
  }
})

const generateId = () =>
  Math.random()
    .toString(36)
    .substring(2, 15)

function ManageRatingForm({
  criteriaItem,
  closeModal,
  handleSubmit,
  initialize,
  collectionName
}) {
  const { addDocumentWithFirebaseId } = useFirestore(collectionName)
  const [ratingCriteria, setRatingCriteria] = React.useState({
    iconName: '',
    multiplier: '1',
    label: '',
    name: '',
    id: generateId()
  })
  const { t } = useTranslation('proposals')

  const handleChange = event => {
    setRatingCriteria({
      ...ratingCriteria,
      [event.target.name]: event.target.value
    })
  }

  const handleChangeIcon = event => {
    setRatingCriteria({
      ...ratingCriteria,
      iconName: event.target.value
    })
  }

  const saveRatings = async () => {
    const name = ratingCriteria.name
      ? ratingCriteria.name
      : ratingCriteria.label.toLowerCase().replace(/\s/g, '_')
    const newData = {
      ...ratingCriteria,
      name
    }
    // console.log('newData:', newData)

    await addDocumentWithFirebaseId(newData)
    closeModal()
  }

  React.useEffect(() => {
    if (criteriaItem) {
      // initialize redux-form data
      initialize(criteriaItem)
      setRatingCriteria(criteriaItem)
    }
  }, [criteriaItem, initialize])

  return (
    <form onSubmit={handleSubmit(saveRatings)}>
      <div className="px-3 pt-3 pb-0">
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={12} md={2}>
            <div className="sm text-md-right">{t('Icon')}</div>
          </Grid>
          <Grid item xs={12} md={10}>
            <RatingCriteriaIconOptions
              criteriaItemIcon={ratingCriteria.iconName}
              handleChangeIcon={handleChangeIcon}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={12} md={2}>
            <div className="sm text-md-right">{t('Criteria')}</div>
          </Grid>
          <Grid item xs={12} md={10}>
            <Field
              component={TextField}
              id="criteria"
              name="label"
              onChange={handleChange}
              size="md"
              placeholder={t('Criteria')}
              validate={[required()]}
              validation
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={12} md={2}>
            <div className="sm text-md-right">{t('Multiplier')}</div>
          </Grid>
          <Grid item xs={12} md={10}>
            <Field
              component={TextField}
              id="multiplier"
              name="multiplier"
              onChange={handleChange}
              size="md"
              placeholder="1.00"
              validate={[required()]}
              validation
            />
          </Grid>
        </Grid>
      </div>
      <div className="pt-1 m-3 d-flex align-items-center justify-content-end">
        <Button
          size="md"
          variant="outlined"
          onClick={closeModal}
          color="primary"
        >
          {t('Cancel')}
        </Button>
        <Button
          style={{ marginLeft: '8px' }}
          type="submit"
          size="md"
          variant="contained"
          color="primary"
        >
          {t('Save')}
        </Button>
      </div>
    </form>
  )
}

const ManageRatingReduxForm = reduxForm({
  form: 'ManageRatingForm' // a unique identifier for this form
})(withStyles(styles)(ManageRatingForm))

const mapDispatchToProps = dispatch => {
  return {
    updateDataBase: (coll, doc, data) =>
      dispatch(updateDataBase(coll, doc, data)),
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data))
  }
}

const mapStateToProps = state => {
  return {
    deletedUsers: state.firestore.ordered.deletedUsers
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'deletedUsers' }])
)(withSnackbar(withStyles(styles)(ManageRatingReduxForm)))
