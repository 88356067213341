import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

import './index.scss'
import App from './App'
import createStore from './redux/store'
import Spinner from './spinner/Spinner'
import './i18n'
import { firebase } from './util/fbconfig'
import * as serviceWorker from './serviceWorker'

const initialState = window.___INITIAL_STATE__ || {
  firebase: { authError: null }
}

const store = createStore(initialState)

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  attachAuthIsReady: true,
  enableLogging: false
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <Spinner />
  return children
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthIsLoaded>
        <App />
      </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
