import { useSelector } from 'react-redux'
import useCollection from '../competition-info/submission/hooks/useCollection'

export default function useShortlistedProposals(dependency) {
  const auth = useSelector(state => state.firebase.auth)
  const { documents: shortlistedProposals } = useCollection(
    'shortlisted-proposals',
    'userId',
    auth.uid,
    dependency
  )

  const count = shortlistedProposals
    ? Object.keys(shortlistedProposals).length
    : 0

  const isProposalShortlisted = proposalId => {
    const result =
      shortlistedProposals &&
      Object.values(shortlistedProposals).some(
        doc => doc.proposalId === proposalId
      )
    return result
  }

  const getShortlistedProposalFirebaseId = proposalId => {
    return (
      shortlistedProposals &&
      Object.entries(shortlistedProposals).find(
        ([key, doc]) => doc.proposalId === proposalId
      )[0]
    )
  }

  return {
    shortlistedProposals,
    count,
    isProposalShortlisted,
    getShortlistedProposalFirebaseId
  }
}
