import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts'
import styled from 'styled-components'

import TooltipMUI from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

import CustomModal, { ModalMain } from '../modal/CustomModal'
import { useTranslation } from 'react-i18next'
import {
  MdConstruction,
  MdEco,
  MdInsertChart,
  MdLocalActivity,
  MdOutlineArchitecture
} from 'react-icons/md'
import { GiModernCity } from 'react-icons/gi'
import { FaBorderNone, FaPeopleGroup } from 'react-icons/fa6'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { BsBarChartSteps, BsFillPeopleFill } from 'react-icons/bs'
import { GrCheckbox } from 'react-icons/gr'
import { PiTrafficSignFill } from 'react-icons/pi'
import { FaParking } from 'react-icons/fa'

const CustomModalMain = styled(ModalMain)`
  width: 1000px;
  padding: 4rem 1rem;
  text-align: center;

  h3 {
    margin-bottom: 3rem;
  }
`

const strokeColors = [
  '#8884d8',
  '#61be65',
  '#f3e200',
  '#ffa632',
  '#ff4c4c',
  '#D34E24',
  '#3f51b5',
  '#61a8be',
  '#bc61be',
  '#dedede',
  '#a74c32',
  '#b6a55c',
  '#ffa8ad',
  '#710627',
  '#38726C',
  '#BAA898',
  '#A57548',
  '#34435E',
  '#AA6373',
  '#03440C',
  '#FF8552',
  '#39393A',
  '#DF3B57',
  '#717744'
]

const renderLines = (arr, opacity) =>
  arr.map((reviewer, i) => (
    <Line
      key={reviewer}
      type="monotone"
      dataKey={reviewer}
      stroke={strokeColors[i]}
      strokeWidth="2"
      strokeOpacity={opacity[reviewer]}
    />
  ))

const renderCustomAxisTick = ({ x, y, payload }) => {
  let svg = ''

  switch (payload.value) {
    case 'Total':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Yleisarvio"
          placement="bottom"
        >
          <>
            <MdInsertChart size="1.5rem" x={x - 10} y={y + 8} />
          </>
        </TooltipMUI>
      )
      break
    case 'Arkkitehtoninen laatu':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Arkkitehtoninen laatu"
          placement="bottom"
        >
          <>
            <MdOutlineArchitecture size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Ratkaisun omaperäisyys':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Ratkaisun omaperäisyys"
          placement="bottom"
        >
          <>
            <HiOutlineLightBulb size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Kaupunkikuvallinen laatu':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Kaupunkikuvallinen laatu"
          placement="bottom"
        >
          <>
            <GiModernCity size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Uuden ja vanhan yhdistäminen':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Uuden ja vanhan yhdistäminen"
          placement="bottom"
        >
          <>
            <MdConstruction size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Tilojen muunneltavuus ja monikäyttöisyys':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Tilojen muunneltavuus ja monikäyttöisyys"
          placement="bottom"
        >
          <>
            <BsBarChartSteps size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Näyttämö- ja katsomotilat':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Näyttämö- ja katsomotilat"
          placement="bottom"
        >
          <>
            <GrCheckbox size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Tekniset tilat':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Tekniset tilat"
          placement="bottom"
        >
          <>
            <MdLocalActivity size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Yleisötilat':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Yleisötilat"
          placement="bottom"
        >
          <>
            <FaPeopleGroup size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Henkilökunnan tilat':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Henkilökunnan tilat"
          placement="bottom"
        >
          <>
            <BsFillPeopleFill size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Logistiikka ja liikenneratkaisut':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Logistiikka ja liikenneratkaisut"
          placement="bottom"
        >
          <>
            <PiTrafficSignFill size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Pysäköinnin ratkaisut':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Pysäköinnin ratkaisut"
          placement="bottom"
        >
          <>
            <FaParking size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Toimintojen sijoittuminen tontilla ja ulkotilat':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Toimintojen sijoittuminen tontilla ja ulkotilat"
          placement="bottom"
        >
          <>
            <FaBorderNone size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Vähähiilisyys, ympäristö. ja energiatehokkuus, kestävyysnäkökulmat, kiertotalous':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Vähähiilisyys, ympäristö. ja energiatehokkuus, kestävyysnäkökulmat, kiertotalous"
          placement="bottom"
        >
          <>
            <MdEco size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Teknistaloudellinen toteutettavuus':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Teknistaloudellinen toteutettavuus"
          placement="bottom"
        >
          <>
            <MdInsertChart size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break

    default:
      svg = ''
  }

  return svg
}

const createLegendOpacityObj = lineKeyDataArr => {
  const outputObj = {}

  lineKeyDataArr.map(
    reviewer =>
      (outputObj['opacity'] = {
        ...outputObj.opacity,
        [reviewer]: 1
      })
  )

  return outputObj
}

const MultipleLinesChart = ({ isOpen, close, allRates, proposal }) => {
  const lineKeyDataArr = Object.keys(allRates.rates[0]).filter(
    e => e !== 'name'
  )
  const legendOpacityObj = createLegendOpacityObj(lineKeyDataArr)
  const [linesOpacity, setLinesOpacity] = useState(legendOpacityObj)
  const { t } = useTranslation('proposals')

  const handleMouseEnter = o => {
    // o is default object from recharts and it's available on mouse event
    const { dataKey } = o

    setLinesOpacity({
      opacity: {
        ...linesOpacity.opacity,
        [dataKey]: 0.5
      }
    })
  }

  const handleMouseLeave = o => {
    // o is default object from recharts and it's available on mouse event
    const { dataKey } = o

    setLinesOpacity({
      opacity: {
        ...linesOpacity.opacity,
        [dataKey]: 1
      }
    })
  }

  return (
    <CustomModal show={isOpen} handleClose={close}>
      <CustomModalMain>
        <h3 style={{ marginTop: '40px' }}>
          {t('Proposal')} {proposal.number} - {proposal.name}
        </h3>

        <LineChart
          width={800}
          height={550}
          data={allRates.rates}
          style={{
            cursor: 'pointer',
            display: 'flex',
            margin: '0 auto'
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            angle={30}
            height={50}
            tick={renderCustomAxisTick}
          />
          <YAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
          <Tooltip />
          <Legend
            layout="vertical"
            verticalAlign="top"
            align="right"
            wrapperStyle={{ right: '0', transform: 'translateX(30%)' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />

          {/* Lines */}
          {renderLines(lineKeyDataArr, linesOpacity.opacity)}
        </LineChart>
      </CustomModalMain>
    </CustomModal>
  )
}

MultipleLinesChart.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  allRates: PropTypes.object.isRequired,
  proposal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number
  ]).isRequired
}

export default MultipleLinesChart
