import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
// import CloseIcon from '@material-ui/icons/Close'

import { XdIFrame } from './components/XdIframe'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    width: 'calc(100% - 264px)',
    position: 'fixed',
    top: 60,
    right: 264,
    bottom: 0,
    left: 0,
    backgroundColor: '#6e7072',
    zIndex: 1000
  },
  dialogPaper2: {
    borderRadius: 0,
    width: 'calc(100% - 36px)',
    position: 'fixed',
    top: 60,
    right: 264,
    bottom: 0,
    left: 0,
    backgroundColor: '#6e7072',
    zIndex: 1000
  },
  container: {
    height: '100%',
    padding: '0 24',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column'
  },
  closeButton: {
    position: 'absolute',
    minWidth: '190px !important',
    zIndex: 200,
    left: 8,
    top: 5,
    padding: 8,
    backgroundColor: '#f26524',
    color: '#fff',
    borderRadius: '2px !important',
    textTransform: 'none',
    fontWeight: 'bold',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#dd5c21'
    }
  }
})

const ElementWith3dView = ({ isOpen, classes, expand, close }) => {
  const { t } = useTranslation('proposals')
  return (
    <>
      <div
        className={expand ? classes.dialogPaper2 : classes.dialogPaper}
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <div className={classes.container}>
          <div className="position-relative">
            <Fab
              variant="extended"
              size="small"
              aria-label="close 3d model view"
              className={classes.closeButton}
              onClick={close}
            >
              {/* <CloseIcon variant="contained" /> */}
              {t('Close 3D model view')}
            </Fab>
          </div>
          <XdIFrame />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    reactAppUrl: state.proposals.envValues.reactAppUrl,
    proposals: state.proposals.data
  }
}

Element.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  link: PropTypes.string,
  expand: PropTypes.bool,
  noSlider: PropTypes.bool,
  element: PropTypes.object,
  close: PropTypes.func.isRequired,
  reactAppUrl: PropTypes.string.isRequired,
  elements: PropTypes.object,
  match: PropTypes.object.isRequired,
  openSingleElement: PropTypes.func,
  comparing: PropTypes.bool,
  history: PropTypes.object.isRequired,
  proposals: PropTypes.object.isRequired,
  changeTab: PropTypes.func,
  id: PropTypes.string
}

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(ElementWith3dView))
)
