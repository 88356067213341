import * as React from 'react'
import styled from 'styled-components/macro'
import { AccountBox, FiberNew } from '@material-ui/icons'
import { connect } from 'react-redux'

import CommentSelect from './CommentSelect'
import { adminRoles } from '../../constant'

function CommentCard({ comment, profile }) {
  return (
    <Card>
      <CardHeader>
        <LeftColumn>
          <AccountBox color="primary" style={{ width: 50, height: 50 }} />
          <div>
            <Name style={{ marginBottom: 0, marginLeft: 2, fontSize: 14 }}>
              {comment?.name}
            </Name>
            <TimeStamp>{`${comment?.createdAt
              .toDate()
              .toLocaleDateString()}, ${comment?.createdAt
              .toDate()
              .toLocaleTimeString()}`}</TimeStamp>
          </div>
        </LeftColumn>
        <RightColumn>
          {comment?.isNew ? (
            <FiberNew
              color="secondary"
              style={{ marginRight: 10, color: '#f93838' }}
            />
          ) : null}
          {adminRoles.includes(profile.role) ? (
            <CommentSelect comment={comment} />
          ) : null}
        </RightColumn>
      </CardHeader>
      <CardContent>{comment?.content}</CardContent>
    </Card>
  )
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  }
}

export default connect(mapStateToProps)(CommentCard)

const Card = styled.div`
  border-radius: 0;
  height: 100%;
  padding: 20px;
  background-color: #ffffff;
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LeftColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RightColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Name = styled.h3`
  margin: 0;
`

const TimeStamp = styled.p`
  margin: 0;
  color: #6e7072;
  font-size: 14px;
`

const CardContent = styled.div`
  margin-top: 1.5rem;
`
