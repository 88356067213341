import React from 'react'
import styled from 'styled-components'

export default function ToggleSwitch({ itemId, checked, onChange }) {
  return (
    <>
      <Input
        type="checkbox"
        id={itemId}
        checked={checked}
        onChange={onChange}
      />
      <Label htmlFor={itemId}></Label>
    </>
  )
}

const Input = styled.input`
  /* overall size */
  --size: 1.5em;

  /* Finetune switch */
  --font-size: 1em;

  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  &:checked + label::before {
    transition: 0.5s;
    background-color: #4caf50;
  }

  &:checked + label::after {
    transform: translate(
      calc(var(--width) - 100% - var(--border)),
      var(--border)
    );
    background-color: #fefefe;
  }
`

const Label = styled.label`
  --border: 0.15em;
  --height: 1.2em;
  --width: 2.2em;
  --switch-color-unchecked: lightgrey;

  display: block;
  position: relative;
  cursor: pointer;
  /* padding-left: calc(var(--width) + 1em); */
  min-width: var(--width);
  min-height: var(--height);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out;
    box-sizing: border-box;
  }

  /* Switch Background */
  &::before {
    /* z-index: 1; */
    background-color: var(--switch-color-unchecked);
    width: var(--width);
    height: var(--height);
    border-radius: calc(var(--height) * 0.5);
    /* box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.2); */
  }

  /* Dot */
  &::after {
    /* z-index: 2; */
    background-color: #fefefe;
    height: calc(var(--height) - (var(--border) * 2));
    width: calc(var(--height) - (var(--border) * 2));
    transform: translate(var(--border), var(--border));
    border-radius: calc(var(--height) / 2);
  }

  &:active::after {
    width: calc(var(--height) * 1.2);
    max-width: calc(var(--height) * 2);
  }
`
