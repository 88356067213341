import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withSnackbar } from 'notistack'
import { firestoreConnect } from 'react-redux-firebase'
import { withTranslation } from 'react-i18next'

import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import LockIcon from '@material-ui/icons/Lock'
import InfoIcon from '@material-ui/icons/Info'
import PublicIcon from '@material-ui/icons/Public'
import CommentIcon from '@material-ui/icons/Comment'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'
// import { Box, Snackbar } from '@material-ui/core';
// import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import { GrChatOption } from 'react-icons/gr'

// import Comments from './Comments/index';
import { sortFirstToLast } from '../redux/actions/proposalsActions'
import { addToDataBase, deleteFromDataBase } from '../redux/actions/dataBase'
import Notes from './Notes'
import Tags from './Tags'
// import Review from './Review';
// import Rating from './Rating'
import * as Helper from '../util/Helper.js'
import Button from '../inputs/Button'
import Modal from '../modal'
import Chat from './Chat'
import Comments from './Comments'
import { adminRoles } from '../constant'
// import RatingForSpecialists from './RatingForSpecialists'

const styles = theme => ({
  tabs: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
    minHeight: '2rem',
    borderBottom: '1px solid #e0e2e4'
  },
  tab: {
    fontSize: '0.875rem',
    fontFamily: 'HarmoniaSansStd !important',
    padding: 0,
    textAlign: 'left',
    minWidth: 0,
    minHeight: 24,
    marginBottom: 0,
    marginRight: 0,
    fontWeight: 700
  },
  tabLabel: {
    padding: '0.25rem 1rem',
    textTransform: 'capitalize',
    color: theme.modeColors.textColor
  },
  selected: {
    color: `${theme.modeColors.selectedColor} !important`
  },
  tooltip: {
    fontSize: '0.875rem',
    padding: '8px 12px'
  },
  flexContainer: {
    paddingBottom: '7px'
  },
  newComments: {
    position: 'absolute',
    right: '6px',
    top: 0,
    padding: '5px 4px',
    background: 'red',
    color: 'white',
    borderRadius: '100%',
    fontSize: '11px',
    lineHeight: 0.5,
    fontWeight: 700
  }
})

export function checkNumberIsFloat(value) {
  return Number(value) === value && value % 1 !== 0
}

// function getDescriptionShort(proposal) {
//   const shortTextObj =
//     proposal.descriptions.length > 0
//       ? proposal.descriptions.find(element => Boolean(element.short))
//       : {}
//   const html = !_.isEmpty(shortTextObj)
//     ? shortTextObj.short
//     : '<p>No description summary</p>'

//   return html
// }

function getDescriptionLong(proposal) {
  const longTextObj =
    proposal.descriptions.length > 0
      ? proposal.descriptions.find(element => Boolean(element.long))
      : {}
  const html = !_.isEmpty(longTextObj)
    ? longTextObj.long
    : '<p>No description</p>'

  return html
}

export function renderScopeDataView(proposal, t) {
  return !_.isEmpty(proposal.excelData) &&
    proposal?.excelData?.competitionEntry[t('EN')].length > 0 ? (
    <Grid container spacing={1}>
      <Grid item xs={7}>
        <Box height="20px" mb={2} fontSize={12} fontWeight={700}>
          {proposal.excelData.headers[t('EN')][0]}
        </Box>
      </Grid>

      <Grid item xs={5}>
        <Box
          height="20px"
          mb={2}
          fontSize={12}
          textAlign="right"
          fontWeight={700}
        >
          {proposal.excelData.headers[t('EN')][2]}
        </Box>
      </Grid>

      {proposal.excelData.competitionEntry[t('EN')].map(
        ({ name, value, unit, subCategories }) => (
          <React.Fragment key={name}>
            <React.Fragment>
              <Grid item xs={8}>
                <Box mb="5px" key={name} fontSize={12}>
                  {name}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box mb="5px" fontSize={12} textAlign="right">
                  {checkNumberIsFloat(value) ? value.toFixed(2) : value}
                  <span style={{ display: 'inline-block', marginLeft: 5 }}>
                    {unit === 'units' ? t('units') : t('sqm')}
                  </span>
                </Box>
              </Grid>
            </React.Fragment>

            {!!subCategories ? (
              <Grid container spacing={1} style={{ margin: 0 }}>
                {subCategories.map(({ name, value }) => (
                  <React.Fragment key={name}>
                    <Grid item xs={8}>
                      <Box mb="5px" key={name} fontSize={10} pl="10px">
                        {name}
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box mb="5px" fontSize={10} textAlign="right">
                        {checkNumberIsFloat(value) ? value.toFixed(2) : value}
                      </Box>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            ) : null}
          </React.Fragment>
        )
      )}
    </Grid>
  ) : (
    <p>{t('No scope data')}</p>
  )
}

class ElementTabs extends Component {
  state = {
    tab: 0,
    summary: false,
    description: false,
    firestorePublicTags: []
  }

  onChangeTab = (e, v) => {
    this.setState({ tab: v })
  }

  openSumarry = () => this.setState({ summary: true })

  closeSumarry = () => this.setState({ summary: false })

  openDescription = () => this.setState({ description: true })

  closeDescription = () => this.setState({ description: false })

  createHearingIdProposalIdPair = () => {
    const { proposals, hearingData } = this.props
    // Keep proposals data in the same order to match with kerrokantasi key
    const sortedProposals = sortFirstToLast(proposals)
    let hearingsId
    let hearingIdProposalIdPair
    const proposalsId = Object.keys(sortedProposals)

    if (hearingData.hearingData) {
      hearingsId = hearingData.hearingData.map(({ id }) => id).slice(2)

      hearingIdProposalIdPair = proposalsId.reduce(function(acc, item, i) {
        return Object.assign(acc, { [item]: hearingsId[i] })
      }, {})
    }

    return hearingIdProposalIdPair
  }

  downLoadFile = async () => {
    const fileSrc = this.props.proposal['3d_model_url']

    try {
      // event.stopPropagation();
      const response = await fetch(fileSrc)
      const blob = await response.blob()
      const fileUrl = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = fileUrl
      a.download = fileUrl
      a.click()
    } catch (error) {}
  }

  numberOfNewComments = () => {
    const { comments, proposalId } = this.props
    if (!_.isEmpty(comments) && !!proposalId) {
      const proposalComments = Helper.getProposalComments(comments, proposalId)
      const total = _.reduce(
        proposalComments,
        (sum, comment) => {
          if (comment.isNew) {
            return (sum += 1)
          }
        },
        0
      )

      return total
    }
  }

  render() {
    const { tab, description } = this.state
    const {
      classes,
      proposal,
      proposalId,
      PublicTags,
      profile,
      proposalsData,
      users,
      openRatingModal,
      openAllRatingsModal,
      t
    } = this.props
    const proposalPublicNote = Helper.getProposalPublicNote(
      proposalsData,
      proposalId
    )
    const proposalPrivateNote = Helper.getProposalPrivateNote(
      profile,
      proposalId
    )
    // const proposalPublicReview = Helper.getProposalPublicReview(
    //   proposalsData,
    //   proposalId,
    //   'en'
    // );
    // const proposalPublicReviewFI = Helper.getProposalPublicReview(
    //   proposalsData,
    //   proposalId,
    //   'fi'
    // );
    // const proposalPrivateRivew = Helper.getProposalPrivateReview(profile, proposalId);
    const proposalPublicTags = Helper.getProposalPublicTags(
      proposalId,
      proposalsData
    )
    const proposalPrivateTags = Helper.getProposalPrivateTags(
      profile,
      proposalId
    )
    const privateTags = _.get(profile, 'PrivateTags', [])
    const proposalPrivateRate = Helper.getProposalPrivateRate(
      profile,
      proposalId
    )
    const proposalPublicRate = Helper.getProposalPublicRate(
      proposalsData,
      proposalId
    )
    // const proposalPublicRateForSpecialists = Helper.getProposalPublicRateForSpecialists(
    //   proposalsData,
    //   proposalId
    // )
    const proposalAllRate = Helper.getProposalAllRate(users, proposalId)
    // const proposalAllRateForSpecialists = Helper.getProposalAllRateForSpecialists(
    //   users,
    //   proposalId
    // )
    // const sectionIdForEachProposalID = this.createHearingIdProposalIdPair() || {};
    const numOfNewComments = this.numberOfNewComments()

    return (
      <>
        <Tabs
          value={tab}
          classes={{ root: classes.tabs, flexContainer: classes.flexContainer }}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.onChangeTab}
        >
          <Tooltip classes={{ tooltip: classes.tooltip }} title={t('Private')}>
            <Tab
              label={<LockIcon style={{ width: '15px', height: '15px' }} />}
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel
              }}
            />
          </Tooltip>

          <Tooltip classes={{ tooltip: classes.tooltip }} title={t('Common')}>
            <Tab
              label={<PublicIcon style={{ width: '15px', height: '15px' }} />}
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel
              }}
            />
          </Tooltip>

          <Tooltip classes={{ tooltip: classes.tooltip }} title={t('Info')}>
            <Tab
              label={<InfoIcon style={{ width: '15px', height: '15px' }} />}
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel
              }}
            />
          </Tooltip>

          <Tooltip classes={{ tooltip: classes.tooltip }} title={t('Chat')}>
            <Tab
              label={<GrChatOption style={{ width: '15px', height: '15px' }} />}
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel
              }}
            />
          </Tooltip>

          {/* <div style={{ position: 'relative' }}></div> */}
          <Tooltip classes={{ tooltip: classes.tooltip }} title={t('Comments')}>
            <Tab
              label={
                <>
                  <CommentIcon
                    style={{
                      position: 'relative',
                      width: '15px',
                      height: '15px'
                    }}
                  />
                  {!!numOfNewComments && tab !== 4 ? (
                    <div className={classes.newComments}>
                      {numOfNewComments}
                    </div>
                  ) : null}
                </>
              }
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel
              }}
            />
          </Tooltip>
        </Tabs>

        {tab === 0 && (
          <div>
            <div className="py-2 border-bottom">
              <Notes
                text={proposalPrivateNote}
                edit={true}
                title={t('My private quick notes')}
                onSave={note =>
                  Helper.updatePrivateNote(note, proposalId, this.props)
                }
              />
            </div>

            {/* <div className="py-2 border-bottom">
              <Review
                edit={true}
                title={profile.role !== 'judge' ? 'Jury review setup' : 'My review'}
                proposal={proposal}
                onSave={review =>
                  Helper.updatePrivateReview(review, proposalId, this.props)
                }
                review={proposalPrivateRivew}
              />
            </div> */}

            <div className="py-2 border-bottom">
              <Tags
                title={
                  adminRoles.includes(profile.role)
                    ? t('My private tags')
                    : t('My tags')
                }
                edit={true}
                tags={privateTags}
                proposalTags={proposalPrivateTags}
                onCreate={tag =>
                  Helper.createPrivateTag(
                    tag,
                    privateTags,
                    proposalId,
                    proposalPrivateTags,
                    this.props
                  )
                }
                removeTag={tagId =>
                  Helper.removePrivateTag(tagId, privateTags, this.props)
                }
                addTagToProposal={tag =>
                  Helper.addPrivateTagToProposal(
                    tag,
                    proposalId,
                    proposalPrivateTags,
                    this.props
                  )
                }
                removeTagFromProposal={tagId =>
                  Helper.removePrivateTagFromProposal(
                    tagId,
                    proposalId,
                    proposalPrivateTags,
                    this.props
                  )
                }
                proposal={proposal}
              />
            </div>
            {/* {adminRoles.includes(profile.role) || profile.role === 'judge' ? (
              <div className="pt-2 pb-1 border-bottom">
                {adminRoles.includes(profile.role) && (
                  <h5 className="text-center mb-1">{t('Jury rating')}</h5>
                )}
                <Rating
                  title={
                    adminRoles.includes(profile.role)
                      ? t('My rating setup')
                      : t('My rating')
                  }
                  edit="true"
                  rate={
                    adminRoles.includes(profile.role)
                      ? proposalPublicRate
                      : proposalPrivateRate
                  }
                  allRates={proposalAllRate}
                  secretary={adminRoles.includes(profile.role)}
                  openRatingModal={openRatingModal}
                  openAllRatingsModal={openAllRatingsModal}
                  proposalId={proposalId}
                />
              </div>
            ) : null} */}
            {/* {adminRoles.includes(profile.role) ||
            profile.role === 'specialist' ? (
              <div className="pt-2 pb-1 border-bottom">
                {adminRoles.includes(profile.role) && (
                  <h5 className="text-center mb-1">{t('Experts’ rating')}</h5>
                )}
                <RatingForSpecialists
                  title={
                    adminRoles.includes(profile.role)
                      ? t('My rating setup')
                      : t('My rating')
                  }
                  edit="true"
                  rate={
                    adminRoles.includes(profile.role)
                      ? proposalPublicRateForSpecialists
                      : proposalPrivateRate
                  }
                  allRates={proposalAllRateForSpecialists}
                  secretary={adminRoles.includes(profile.role)}
                  openRatingModalForSpecialist={openRatingModalForSpecialist}
                  openAllRatingsModalForSpecialist={
                    openAllRatingsModalForSpecialist
                  }
                  proposalId={proposalId}
                />
              </div>
            ) : null} */}
          </div>
        )}

        {tab === 1 && (
          <div>
            <div className="py-2 border-bottom">
              <Notes
                text={proposalPublicNote}
                title={t('Secretary notes')}
                edit={adminRoles.includes(profile.role)}
                onSave={note =>
                  Helper.updatePublicNote(note, proposalId, this.props)
                }
              />
            </div>

            {/* <div className="py-2 border-bottom">
              <Review
                edit={profile.role !== 'judge'}
                title="Jury review"
                proposal={proposal}
                onSave={review =>
                  Helper.updatePublicReview(
                    review,
                    proposalId,
                    this.props,
                    this.props.i18n.language
                  )
                }
                review={proposalPublicReview}
                reviewFI={proposalPublicReviewFI}
                enableLang
              />
            </div> */}

            <div className="py-2 border-bottom">
              <Tags
                title={t('Jury tags')}
                edit={adminRoles.includes(profile.role)}
                onCreate={tag =>
                  Helper.createPublicTag(
                    tag,
                    this.props,
                    proposalId,
                    proposalPublicTags
                  )
                }
                tags={PublicTags ? PublicTags : []}
                addTagToProposal={tag =>
                  Helper.addPublicTagToProposal(
                    tag,
                    proposalId,
                    this.props,
                    proposalPublicTags
                  )
                }
                proposalTags={proposalPublicTags}
                removeTag={tagId => Helper.removePublicTag(tagId, this.props)}
                removeTagFromProposal={tagId =>
                  Helper.removePublicTagFromProposal(
                    tagId,
                    proposalId,
                    proposalPublicTags,
                    this.props
                  )
                }
                proposal={proposal}
              />
            </div>
            {/* {adminRoles.includes(profile.role) || profile.role === 'judge' ? (
              <div className="pt-2 pb-1 border-bottom">
                <Rating
                  title={t('Jury rating')}
                  proposal={proposal}
                  rate={proposalPublicRate}
                  proposalId={proposalId}
                  allRates={proposalAllRate}
                />
              </div>
            ) : null} */}
            {/* {adminRoles.includes(profile.role) ||
            profile.role === 'specialist' ? (
              <div className="pt-2 pb-1 border-bottom">
                <RatingForSpecialists
                  title={t('Specialist rating')}
                  proposal={proposal}
                  rate={proposalPublicRate}
                  proposalId={proposalId}
                  allRates={proposalAllRate}
                />
              </div>
            ) : null} */}
          </div>
        )}

        {tab === 2 && (
          <div className="py-2">
            {/* <Button
              style={{ width: '100%', marginBottom: '1rem' }}
              size="md"
              variant="contained"
              color="primary"
              onClick={this.openDescription}
            >
              {t('Description')}
            </Button> */}
            {renderScopeDataView(proposal, t)}

            <div>
              {/* <Button
                style={{ width: '100%', marginTop: '2rem' }}
                size="md"
                variant="contained"
                color="primary"
                onClick={this.openSumarry}
              >
                {t('Description summary')}
              </Button> */}
              {/* <Button
                style={{ width: '100%', marginTop: '1rem' }}
                size="md"
                variant="contained"
                color="primary"
                onClick={this.openDescription}
              >
                {t('Description')}
              </Button> */}

              {/* {proposal['3d_model_url'] !== '' ? (
                <DownloadFile proposal={proposal} />
              ) : (
                <div style={{ marginTop: '1rem' }}>No 3D model</div>
              )} */}

              {/* <Button
                style={{ marginTop: '1rem' }}
                size="md"
                variant="contained"
                color="primary"
              >
                <a
                  href="https://www.autodesk.com/products/fbx/fbx-review"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#fff', textDecoration: 'none' }}
                >
                  Download 3D viewer app
                </a>
              </Button> */}
            </div>
          </div>
        )}

        {tab === 3 && <Chat proposalId={proposalId} />}

        {tab === 4 && <Comments proposalId={proposalId} activeTab={tab} />}

        {/* the Summary description and Description fetch from the excelData.description by index it should change on the future */}
        {/* <Modal
          close={this.closeSumarry}
          isOpen={summary}
          title={t('Description summary')}
          proposal={proposal}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: getDescriptionShort(proposal)
            }}
          />
        </Modal> */}
        <Modal
          close={this.closeDescription}
          isOpen={description}
          title={t('Description')}
          proposal={proposal}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: getDescriptionLong(proposal)
            }}
          />
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    proposals: state.proposals.data,
    hearingData: state.hearingData,
    userId: state.firebase.auth.uid,
    profile: state.firebase.profile,
    PublicTags: state.firestore.ordered.PublicTags,
    users: state.firestore.ordered.users,
    proposalsData: state.firestore.ordered.proposals,
    comments: state.firestore.data.comments
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) =>
      dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'users' },
    { collection: 'proposals' },
    { collection: 'PublicTags' },
    { collection: 'comments' }
  ])
)(withSnackbar(withStyles(styles)(withTranslation('proposals')(ElementTabs))))

// function DownloadFile({ proposal }) {
//   console.log('proposal:', proposal);
//   const [isClicked, setIsClicked] = React.useState(false);
//   const reactAppUrl = process.env.REACT_APP_URL_PHASE_1;
//   const fileSrc = reactAppUrl + proposal['3d_model_url'];
//   const file = proposal['3d_model_url'].split('/')[2];

//   const downloadFile = async event => {
//     setIsClicked(true);
//     try {
//       event.stopPropagation();
//       const response = await fetch(fileSrc);
//       const blob = await response.blob();
//       const fileUrl = window.URL.createObjectURL(blob);
//       let a = document.createElement('a');
//       a.href = fileUrl;
//       a.download = file;
//       a.click();
//     } catch (error) {
//       console.log('Unable to download 3D model file', error);
//     }
//   };

//   const message = `Downloading ${file} file. Please wait ...`;
//   return (
//     <>
//       <Button
//         style={{ marginTop: '1rem' }}
//         size="md"
//         variant="contained"
//         color="primary"
//         onClick={downloadFile}
//       >
//         Download 3D model file
//       </Button>
//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         open={isClicked}
//         autoHideDuration={3000}
//         onClose={() => setIsClicked(false)}
//       >
//         <MySnackbarContentWrapper
//           message={message}
//           variant="info"
//           onClose={() => setIsClicked(false)}
//         />
//       </Snackbar>
//     </>
//   );
// }
