import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import _ from 'lodash'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import cookie from 'react-cookies'
import { isEmpty } from 'react-redux-firebase'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import SettingsIcon from '@material-ui/icons/Settings'
import Tooltip from '@material-ui/core/Tooltip'
// import ArrowForward from '@material-ui/icons/ArrowForward';
// import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import HomeIcon from '@material-ui/icons/Home'
import Snackbar from '@material-ui/core/Snackbar'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { withTranslation } from 'react-i18next'

import Modal from '../modal'
import MySnackbarContentWrapper from '../inputs/Snackbar'
import AdapterLink from '../util/AdapterLink'
import ProfileMenu from './ProfileMenu'
import { signOut } from '../redux/actions/authActions'
import {
  getProposals,
  setPhaseId,
  setBaseUrlToGetProposalsData,
  setViewMode
} from '../redux/actions/proposalsActions'
import {
  updateCookies,
  filterProposals
} from '../redux/actions/proposalsActions'
// import SelectPhase from './SelectPhase'
import { clearAll } from '../proposals/SearchDetails'
import SelectLanguage from './SelectLanguage'
import { adminRoles, competitionName } from '../constant'

const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },

  toolbar: {
    position: 'relative',
    height: '40px',
    minHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    padding: '10px 30px',
    background: theme.modeColors.navBackground,
    zIndex: 10
  },
  title: {
    display: 'block',
    marginBottom: -3,
    marginLeft: '8px'
  },
  sectionDesktop: {
    display: 'flex',
    alignItems: 'center'
  },

  icons: {
    padding: '10px'
  },

  tooltip: {
    fontSize: '0.875rem',
    padding: '8px 12px'
  },
  logo: {
    width: '80px',
    marginRight: '10px',
    marginTop: '-5px'
  },
  proposalsNav: {
    minWidth: '100px',
    padding: '0 5px',
    textAlign: 'center'
  }
})

class Navbar extends React.Component {
  state = {
    eventKeys: '', // for keydown event
    lastKeyTime: Date.now(), // for keydown event
    snackbarPopupType: null, // for display Snackbar,
    selectPage: false,
    page: 'page 1',
    selectMode: false,
    redirect: false
  }

  intervalID = 0

  componentDidMount = () => {
    document.addEventListener('keydown', this.handleKeyDown)
    this.intervalID = setInterval(this.changeCookies, 250)
  }

  componentDidUpdate(prevProps, prevState) {
    const { cookies } = this.props.proposals
    const { cookies: prevCookies } = prevProps.proposals
    const cookiesQuery = queryString.parse(cookies.replace(/; /g, '&'))
    if (
      cookies !== prevCookies ||
      (cookiesQuery.JUG_MODE && cookiesQuery.JUG_MODE === 'full')
    ) {
      this.updateViewMode(cookiesQuery)
    }

    if (this.state.redirect && !cookiesQuery.JUG_MODE) {
      this.renderRedirect()
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeyDown)
    clearInterval(this.intervalID)
  }

  changeCookies = () => {
    const savedCookies = document.cookie
    if (
      savedCookies !== this.props.proposals.cookies &&
      !this.props.profile.isEmpty
    ) {
      this.props.updateCookies(savedCookies)
    }
  }

  updateViewMode = cookiesQuery => {
    if (this.state.redirect) return
    const { pathname } = this.props.location

    if (cookiesQuery.JUG_MODE) {
      if (cookiesQuery.JUG_MODE === 'full' && !pathname.includes('proposal/')) {
        const id = Object.keys(this.props.proposals.data)[0]
        this.props.history.replace(`/proposal/${id}`)
      }
    }
  }

  navigation = () => {
    const { proposals, location, classes, t } = this.props
    if (proposals.data) {
      const proposalsObj = proposals.search
        ? proposals.searchProposals
        : proposals.data
      const sortedProposalKeys = _.map(proposalsObj, (value, key) => key)
      const arrayLength = sortedProposalKeys.length
      const proposalId = location.pathname.replace('/proposal/', '')
      const currentIndex = sortedProposalKeys.indexOf(proposalId)

      return (
        <div className="d-flex align-items-center">
          {/* Go back */}
          <>
            <span
              onClick={() => this.navigate(proposalsObj, currentIndex, 'back')}
              style={{
                color: currentIndex === 0 ? 'rgba(0, 0, 0, 0.26)' : 'inherit'
              }}
            >
              {t('prev')}
            </span>
            <IconButton
              onClick={() => this.navigate(proposalsObj, currentIndex, 'back')}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                }
              }}
              size="small"
              color="inherit"
              disabled={currentIndex === 0 ? true : false}
            >
              <ArrowUpward fontSize="small" className={classes.icons} />
            </IconButton>
          </>

          {/* Current proposal number/ total proposals */}
          <div className={classes.proposalsNav}>
            {arrayLength} {t('results')}
          </div>

          <>
            {/* Go forward */}
            <IconButton
              onClick={() => this.navigate(proposalsObj, currentIndex, 'next')}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                }
              }}
              size="small"
              color="inherit"
              disabled={currentIndex === arrayLength - 1 ? true : false}
            >
              <ArrowDownward fontSize="small" className={classes.icons} />
            </IconButton>
            <span
              onClick={() => this.navigate(proposalsObj, currentIndex, 'next')}
              style={{
                color:
                  currentIndex === arrayLength - 1
                    ? 'rgba(0, 0, 0, 0.26)'
                    : 'inherit'
              }}
            >
              {t('next')}
            </span>
          </>
        </div>
      )
    }
  }

  // Go back or next
  navigate = (proposalsObj, currentIndex, navCase = 'next') => {
    const { history, location } = this.props
    const query = queryString.parse(location.search)

    const sortedProposalKeys = _.map(proposalsObj, (value, key) => key)

    const targetIndex = navCase === 'next' ? currentIndex + 1 : currentIndex - 1
    let goToKey = sortedProposalKeys[targetIndex]

    if (goToKey) {
      // Reset cookie values when user goes to new proposal
      cookie.save('j_proposal', goToKey, { path: '/' })
      cookie.save('j_count', 0, { path: '/' })

      const obj = { pathname: `/proposal/${goToKey}` }

      if (query.view_mode) {
        obj.search = `?view_mode=${query.view_mode}`
      }

      if (query.start_index) {
        obj.search += `&start_index=${query.start_index}`
      }

      history.replace(obj)
    }
  }

  handleKeyDown = event => {
    const { proposals, history, location } = this.props
    const isNumber = isFinite(event.key)

    if (
      isNumber &&
      event.target.nodeName !== 'TEXTAREA' &&
      event.target.nodeName !== 'INPUT' &&
      event.target.nodeName === 'BODY'
    ) {
      const eventTime = Date.now()
      // Reset eventKeys after 1s between keypress
      if (eventTime - this.state.lastKeyTime > 1000) {
        this.setState({ eventKeys: '' })
      }
      // Set new event keys, new lastKeyTime and snackbarPopupType states
      this.setState({
        eventKeys: this.state.eventKeys + event.key,
        lastKeyTime: eventTime,
        snackbarPopupType: 'info'
      })
    }

    if (event.key === 'Enter') {
      // Convert key from string to number
      const requestedKey = parseInt(this.state.eventKeys)
      // const proposalCount = proposals.data ? Object.keys(proposals.data).length : 0;
      const query = queryString.parse(location.search)

      if (requestedKey) {
        const targetedProposal = Object.entries(proposals.data).filter(
          ([key, proposal]) => requestedKey === proposal.number
        )

        if (targetedProposal.length > 0) {
          const key = targetedProposal[0][0]
          // Go to new proposal
          // Reset cookie values when user goes to new proposal
          cookie.save('j_proposal', key, { path: '/' })
          cookie.save('j_count', 0, { path: '/' })

          const obj = { pathname: `/proposal/${key}` }
          if (query.view_mode) {
            obj.search = 'view_mode=true'
          }
          if (query.start_index) {
            obj.search += `&start_index=${query.start_index}`
          }
          history.replace(obj)
        } else {
          this.setState({ snackbarPopupType: 'error' })
        }
      }
    }

    if (event.code === 'KeyP' && event.target.nodeName === 'BODY') {
      this.setState({ selectPage: true })
    }

    if (event.code === 'KeyM' && event.target.nodeName === 'BODY') {
      this.setState({ selectMode: true })
    }

    const count = cookie.load('j_count') ? parseInt(cookie.load('j_count')) : 0

    if (event.code === 'KeyN' && event.target.nodeName === 'BODY') {
      cookie.save('j_count', count + 3, { path: '/' })
    }

    if (event.code === 'KeyB' && event.target.nodeName === 'BODY') {
      cookie.save('j_count', count > 3 ? count - 3 : 0, { path: '/' })
    }
  }

  handleCloseSnackBar = () => {
    this.setState({
      eventKeys: '',
      snackbarPopupType: null
    })
  }

  renderSnackbarPopup = (type, number) => {
    let snackbarMessage = ''

    type === 'error'
      ? (snackbarMessage = `Couldn't find proposal number ${number}`)
      : (snackbarMessage = `Press 'Enter' to go to proposal number ${number}`)

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={!!number}
        autoHideDuration={3000}
        onClose={this.handleCloseSnackBar}
      >
        <MySnackbarContentWrapper
          onClose={this.handleCloseSnackBar}
          variant={type}
          message={snackbarMessage}
        />
      </Snackbar>
    )
  }

  closeSelectPage = () => this.setState({ selectPage: false })
  closeSelectMode = () => this.setState({ selectMode: false })

  handleChangeRadio = event => {
    const { name, value } = event.target
    const { setViewMode } = this.props

    if (name === 'page') {
      this.setState({ page: value })
    } else if (name === 'viewMode') {
      setViewMode(value)
    }
  }

  onSaveSelectPage = () => {
    window.self.name = this.state.page
    this.closeSelectPage()
  }

  onSaveSelectMode = () => {
    const { viewMode } = this.props

    cookie.remove('JUG_MODE', { path: '/' })
    cookie.save('JUG_MODE', viewMode, { path: '/' })

    if (viewMode === 'full') {
      const id = Object.keys(this.props.proposals.data)[0]
      cookie.save('j_proposal', id, { path: '/' })
      cookie.save('j_count', 0, { path: '/' })
    }

    this.closeSelectMode()
  }

  // Change API urls and refetch data
  changedPhaseHandler = phaseId => {
    const {
      setPhaseId,
      getProposals,
      setBaseUrlToGetProposalsData
    } = this.props
    const targetedPhase = phaseId
    const baseUrl =
      targetedPhase === 'phase-1'
        ? process.env.REACT_APP_URL_PHASE_1
        : process.env.REACT_APP_URL_PHASE_2

    setPhaseId(targetedPhase)
    setBaseUrlToGetProposalsData(baseUrl)
    getProposals()
  }

  competitionNameAndPhase = () => {
    // const { profile, selectedPhase } = this.props

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <img className={classes.logo} src={logo.src} alt={logo.alt} /> */}
        <span style={{ display: 'inline-block', marginLeft: 15 }}>
          {competitionName}
        </span>
        {/* {adminRoles.includes(profile.role) ? (
          <div style={{ marginLeft: 10 }}>
            <SelectPhase
              selectedPhase={selectedPhase}
              changedPhase={this.changedPhaseHandler}
            />
          </div>
        ) : null} */}
      </div>
    )
  }

  quiteFullViewMode = () => {
    const { viewMode, setViewMode, history, filterProposals } = this.props
    // Clear search history (classes, public tags, private tags) and filter proposals data again to show all proposals
    clearAll(filterProposals, history)

    if (viewMode === 'normal') return

    // Quit full view mode
    setViewMode('normal')
    cookie.remove('JUG_MODE', { path: '/' })
    document.body.style.overflow = 'auto'

    this.setState({ redirect: true })
  }

  renderRedirect = () => {
    this.props.history.replace('/settings')
    this.setState({ redirect: false })
  }

  render() {
    const readOnly = process.env.REACT_APP_READ_ONLY
    // viewMode state is moved to Redux store
    // Single-element/index needs setViewMode action to quit 'full' view mode when user click closeModal button
    const { classes, profile, location, viewMode, t } = this.props
    const inProposal = location.pathname.includes('proposal')
    const {
      eventKeys,
      snackbarPopupType,
      selectPage,
      page,
      selectMode
    } = this.state

    if (!isEmpty(profile)) {
      return (
        <AppBar className={classes.root} position="sticky" color="default">
          {/* Show info or error snackbar for keydown event */}
          {eventKeys && this.renderSnackbarPopup(snackbarPopupType, eventKeys)}

          {/* Toolbar */}
          <Toolbar disableGutters={true} className={classes.toolbar}>
            {/* Home icons and Profile's name */}
            <div className="d-flex align-items-center">
              <Tooltip classes={{ tooltip: classes.tooltip }} title={t('Home')}>
                <IconButton
                  component={AdapterLink}
                  to="/"
                  className={classes.icons}
                  color="inherit"
                  onClick={this.quiteFullViewMode}
                >
                  <HomeIcon />
                </IconButton>
              </Tooltip>
              <h5 className={classes.title}>
                {profile.firstName} {profile.lastName}
              </h5>
            </div>

            {/* Project's name */}
            <h3 className="mb-0">
              {inProposal ? this.navigation() : this.competitionNameAndPhase()}
            </h3>

            {/* Competition's info - Settings - Profile */}
            <div className={classes.sectionDesktop}>
              <Tooltip
                component={AdapterLink}
                to="/competition-info"
                classes={{ tooltip: classes.tooltip }}
                title={t('Competition information')}
              >
                <IconButton
                  id="competition-info"
                  className={classes.icons}
                  color="inherit"
                >
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
              {adminRoles.includes(profile.role) && (
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t('Settings')}
                >
                  <IconButton
                    component={AdapterLink}
                    to="/settings"
                    className={classes.icons}
                    color="inherit"
                    onClick={this.quiteFullViewMode}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              )}
              <ProfileMenu Email={profile.email} />

              <SelectLanguage />
            </div>

            {/* Select Page Modal */}
            <Modal
              close={this.closeSelectPage}
              isOpen={selectPage}
              onSave={this.onSaveSelectPage}
              title="Select Page"
            >
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="page"
                  name="page"
                  value={page}
                  onChange={this.handleChangeRadio}
                >
                  <FormControlLabel
                    value="page 1"
                    control={<Radio />}
                    label="Page 1"
                  />
                  <FormControlLabel
                    value="page 2"
                    control={<Radio />}
                    label="Page 2"
                  />
                  <FormControlLabel
                    value="page 3"
                    control={<Radio />}
                    label="Page 3"
                  />
                </RadioGroup>
              </FormControl>
            </Modal>

            {/* Select View Modal */}
            <Modal
              close={this.closeSelectMode}
              isOpen={selectMode}
              onSave={this.onSaveSelectMode}
              title="Select View Mode"
            >
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="page"
                  name="viewMode"
                  value={viewMode}
                  onChange={this.handleChangeRadio}
                >
                  <FormControlLabel
                    value="normal"
                    control={<Radio />}
                    label="Normal mode"
                  />
                  <FormControlLabel
                    value="full"
                    control={<Radio />}
                    label="Full Screen Element"
                  />
                  <FormControlLabel
                    value="nested"
                    control={<Radio />}
                    label="Three level mode"
                    disabled
                  />
                  <FormControlLabel
                    value="clicking"
                    control={<Radio />}
                    label="Clicking Mode"
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </Modal>
          </Toolbar>
        </AppBar>
      )
    } else if (readOnly) {
      return (
        <AppBar className={classes.root} position="sticky" color="default">
          <Toolbar disableGutters={true} className={classes.toolbar}>
            <div className="d-flex align-items-center">
              <Tooltip classes={{ tooltip: classes.tooltip }} title="Home">
                <Link to="/">
                  {/* <img className={classes.logo} src={logo.src} alt={logo.alt} /> */}
                  Logo
                </Link>
              </Tooltip>
            </div>
            <h3 className="mb-0">{inProposal ? 'nav' : 'Competition'}</h3>
            <div className={classes.sectionDesktop}>
              <Tooltip
                component={AdapterLink}
                to="/competition-info"
                classes={{ tooltip: classes.tooltip }}
                title="Competition information"
              >
                <IconButton
                  id="competition-info"
                  className={classes.icons}
                  color="inherit"
                >
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
      )
    }
    return null
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    proposals: state.proposals,
    selectedPhase: state.proposals.selectedPhase,
    viewMode: state.proposals.viewMode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
    updateCookies: data => dispatch(updateCookies(data)),
    getProposals: () => dispatch(getProposals()),
    setPhaseId: phaseId => dispatch(setPhaseId(phaseId)),
    setBaseUrlToGetProposalsData: baseUrl =>
      dispatch(setBaseUrlToGetProposalsData(baseUrl)),
    setViewMode: viewMode => dispatch(setViewMode(viewMode)),
    filterProposals: search => dispatch(filterProposals(search))
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation('navbar')(withStyles(styles)(Navbar)))
)
