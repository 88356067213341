import React from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Typography, IconButton, Box } from '@material-ui/core'
// import { Skeleton } from '@material-ui/lab'

import {
  MdInsertChart,
  MdModeEditOutline,
  MdLocalActivity,
  MdEco
} from 'react-icons/md'
import { GiStairsGoal, GiModernCity } from 'react-icons/gi'
import { MdOutlineArchitecture, MdConstruction } from 'react-icons/md'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { BsFillPeopleFill, BsBarChartSteps } from 'react-icons/bs'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { FaParking, FaBorderNone } from 'react-icons/fa'
import { FaUsersGear, FaPeopleGroup } from 'react-icons/fa6'
import { TbProgressCheck } from 'react-icons/tb'
import { GrCheckbox } from 'react-icons/gr'
import { PiTrafficSignFill } from 'react-icons/pi'

import ManageRatingCriteria from './ManageRatingCriteria'
import useCollection from '../competition-info/submission/hooks/useCollection'
import DeleteRatingCriteriaAlert from './DeleteRatingCriteriaAlert'
import Button from '../competition-info/submission/components/Button'

const icons = {
  MdInsertChart,
  GiStairsGoal,
  AiOutlineCheckCircle,
  MdOutlineArchitecture,
  HiOutlineLightBulb,
  BsFillPeopleFill,
  BsBarChartSteps,
  GiModernCity,
  MdLocalActivity,
  MdConstruction,
  FaParking,
  MdEco,
  FaUsersGear,
  TbProgressCheck,
  GrCheckbox,
  FaPeopleGroup,
  PiTrafficSignFill,
  FaBorderNone
}

// const initialCriteria = [
//   {
//     id: 'criteria-1',
//     name: 'overall',
//     label: 'Total',
//     iconName: 'MdInsertChart',
//     multiplier: 1
//   },
// ]

export default function RatingCriteria({ collectionName }) {
  return (
    <div>
      <Headers />
      <Body collectionName={collectionName} />
    </div>
  )
}

function Headers() {
  const { t } = useTranslation('proposals')
  return (
    <Grid container alignItems="center" className="py-1 border-bottom">
      <Grid item xs={2} className="sm">
        <Typography>{t('Icon')}</Typography>
      </Grid>
      <Grid item xs={7} className="sm">
        <Typography>{t('Criteria')}</Typography>
      </Grid>
      <Grid item xs={2} className="sm">
        <Typography align="center">{t('Multiplier')}</Typography>
      </Grid>
      <Grid item xs={1} className="sm">
        <Typography align="center"></Typography>
      </Grid>
    </Grid>
  )
}

function Body({ collectionName }) {
  const { documents } = useCollection(collectionName)
  const [criteria, setCriteria] = React.useState([])
  const { t } = useTranslation('proposals')

  React.useEffect(() => {
    if (!!documents && Object.values(documents).length > 0) {
      setCriteria(Object.values(documents))
    } else {
      setCriteria([])
    }
  }, [documents])

  if (!documents || Object.values(documents).length === 0) {
    return (
      <div>
        {/* <Skeleton variant="rect" height={500} /> */}
        <ManageRatingCriteria collectionName={collectionName}>
          <Box textAlign="center" style={{ marginTop: 56 }}>
            <Button type="button" variant="primary">
              <strong>{t('Add criteria')}</strong>
            </Button>
          </Box>
        </ManageRatingCriteria>
      </div>
    )
  }

  return (
    <>
      <Grid container alignItems="center">
        {criteria.map(item => (
          <Item key={item.id} {...item}>
            <Box display="flex" alignItems="enter" style={{ gap: 16 }}>
              <ManageRatingCriteria
                criteriaItem={item}
                collectionName={collectionName}
              >
                <IconButton className="mr-1" size="small" aria-label="Edit">
                  <MdModeEditOutline style={{ width: 18, height: 18 }} />
                </IconButton>
              </ManageRatingCriteria>
              <DeleteRatingCriteriaAlert criteriaItem={item} />
            </Box>
          </Item>
        ))}
      </Grid>
      <ManageRatingCriteria collectionName={collectionName}>
        <Box textAlign="center" style={{ marginTop: 56 }}>
          <Button type="button" variant="primary">
            <strong>{t('Add criteria')}</strong>
          </Button>
        </Box>
      </ManageRatingCriteria>
    </>
  )
}

function Item({ iconName, label, multiplier, children }) {
  const { t } = useTranslation('proposals')
  const Icon = icons[iconName]
  const iconProps = { style: { width: 23, height: 23 } }

  return (
    <>
      <Grid item xs={2} className="py-2 sm">
        {Icon && <Icon {...iconProps} />}
      </Grid>
      <Grid item xs={7} className="py-2 sm">
        <Typography>{t(label)}</Typography>
      </Grid>
      <Grid item xs={2} className="py-2 sm">
        <Typography align="center">
          {parseFloat(multiplier).toFixed(2)}
        </Typography>
      </Grid>
      <Grid item xs={1} className="py-2 sm">
        {children}
      </Grid>
    </>
  )
}
