import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';

import Comment from './Comment';
import { getProposalComments } from '../../util/Helper';
import { updateDataBase } from '../../redux/actions/dataBase';
import { useTranslation } from 'react-i18next';

const Comments = ({ proposalId, comments, updateDataBase }) => {
  const [allComments, setAllComments] = React.useState([]);
  const { t } = useTranslation('proposals');

  React.useEffect(() => {
    if (!_.isEmpty(comments)) {
      const allComments = getProposalComments(comments, proposalId);
      setAllComments(allComments);
    }

    return () => {
      // When user goes away from 'Comments' tab, remove 'new icon' from each comment
      allComments.forEach(comment =>
        updateDataBase('comments', comment.commentId, { ...comment, isNew: false })
          .then(() => {})
          .catch(error => console.log(error))
      );
    };
    // To prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments, proposalId]);

  return (
    <div>
      {allComments.length === 0 ? (
        <p>{t('No comments')}</p>
      ) : (
        allComments.map(comment => {
          return <Comment key={comment.commentId} comment={comment} />;
        })
      )}
    </div>
  );
};

Comments.propTypes = {
  proposalId: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    comments: state.firestore.data.comments,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDataBase: (coll, doc, data) => dispatch(updateDataBase(coll, doc, data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'comments', orderBy: ['createdAt', 'desc'] }])
)(Comments);
