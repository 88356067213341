import React from 'react'
import CodeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'firepad/dist/firepad.css'
import { firebase } from '../util/fbconfig'

export default function CollaborativeReview({
  proposalId,
  profile,
  userId,
  databaseName
}) {
  const [firepad, setFirepad] = React.useState(null)
  const [usersList, setUsersList] = React.useState([])
  const editorRef = React.useRef(null)

  const createEditorAndToolbar = React.useCallback(() => {
    const codeMirror = CodeMirror(editorRef.current, { lineWrapping: true })
    window.CodeMirror = CodeMirror
    // Firepad must have CodeMirror in global before it is required
    const Firepad = require('firepad')
    const firepadRef = firebase
      .database()
      .ref(databaseName)
      .child(proposalId)
    const firePad = Firepad.fromCodeMirror(firepadRef, codeMirror, {
      richTextToolbar: true,
      richTextShortcuts: true,
      userId
    })

    setFirepad(firePad)
  }, [databaseName, proposalId, userId])

  const getUsers = React.useCallback(() => {
    const { firstName, lastName } = profile
    const userData = {
      initials: firstName.split('')[0] + lastName.split('')[0],
      fullName: `${firstName} ${lastName}`
    }
    const usersRef = firebase
      .database()
      .ref(databaseName)
      .child(proposalId)
      .child('users')

    try {
      usersRef.on('value', snapshot => {
        let tempUsersList = []

        snapshot.forEach(function(data) {
          tempUsersList.push({
            id: data.key,
            color: data.val().color,
            ...userData
          })
        })
        setUsersList(tempUsersList)
      })
    } catch (error) {
      console.log('error:', error)
    }
  }, [databaseName, profile, proposalId])

  React.useEffect(() => {
    const init = () => {
      if (firepad) {
        // Remove previous firepad and CodeMirror to get correct content for new proposal
        firepad.dispose()
        editorRef.current.removeChild(editorRef.current.firstChild)
        setUsersList([])
      }

      createEditorAndToolbar()
      getUsers()
    }

    init()
    // do not include firepad in dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createEditorAndToolbar, getUsers, proposalId, userId])

  return (
    <>
      <div style={{ position: 'absolute', top: '80px' }}>
        {usersList.map(user => (
          <div
            key={user.id}
            style={{
              color: '#000',
              marginRight: '10px',
              padding: '10px',
              backgroundColor: user.color,
              borderRadius: '100%',
              display: 'inline-block',
              fontWeight: 'bold'
            }}
          >
            <span>{user.initials}</span>
          </div>
        ))}
      </div>
      <div ref={editorRef} style={{ width: '100%', height: '95%' }} />
    </>
  )
}
