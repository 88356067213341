import React from 'react'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { BiSolidLike } from 'react-icons/bi'

import useFirestore from '../competition-info/submission/hooks/useFirestore'
import useShortlistedProposals from '../hooks/useShortlistedProposals'
import { useTranslation } from 'react-i18next'
import { addToDataBase } from '../redux/actions/dataBase'

const ShortlistButton = ({ id, proposal, classes, auth, addToDataBase }) => {
  const { proposalId, name } = proposal
  const [refresh, setRefresh] = React.useState(false) // Add state to trigger re-fetch
  const { addDocumentWithFirebaseId, deleteDocument } = useFirestore(
    'shortlisted-proposals'
  )
  const {
    count,
    isProposalShortlisted,
    getShortlistedProposalFirebaseId
  } = useShortlistedProposals(refresh)
  const { t } = useTranslation('proposals')

  const isShortlisted = isProposalShortlisted(proposalId)

  const addShortListedData = async () => {
    addToDataBase('users', auth.uid, {
      proposals: { [id]: { shortlisted: true } }
    })
      .then(() => console.log('success'))
      .catch(err => console.log('error:', err))

    addToDataBase('proposals', id, { juryShortListed: { [auth.uid]: true } })
      .then(() => console.log('success'))
      .catch(err => console.log('error:', err))

    await addDocumentWithFirebaseId({
      id,
      userId: auth.uid,
      proposalId,
      proposalName: name
    })
    setRefresh(!refresh) // Trigger re-fetch
  }

  const removeShortListedData = async docId => {
    addToDataBase('users', auth.uid, {
      proposals: { [id]: { shortlisted: false } }
    })
      .then(() => console.log('success'))
      .catch(err => console.log('error:', err))

    addToDataBase('proposals', id, { juryShortListed: { [auth.uid]: false } })
      .then(() => console.log('success'))
      .catch(err => console.log('error:', err))

    await deleteDocument(docId)
    setRefresh(!refresh) // Trigger re-fetch
  }

  const handleShortlist = async () => {
    if (isShortlisted) {
      const docId = getShortlistedProposalFirebaseId(proposalId)
      removeShortListedData(docId)
    } else {
      addShortListedData()
    }
  }

  const btnLabel = isShortlisted ? t('Shortlisted') : t('Shortlist')

  return (
    <div className={classes.wrapper}>
      <button
        type="button"
        className={`${classes.shortlistButton} ${
          isShortlisted ? classes.shortlistButtonActive : ''
        }`}
        onClick={handleShortlist}
      >
        <BiSolidLike style={{ width: 20, height: 20 }} />
        <span className="">{btnLabel}</span>
      </button>
      <Tooltip title={t('Total shortlisted proposals')}>
        <div className={classes.shortlistedCount}>{count}</div>
      </Tooltip>
    </div>
  )
}

const styles = theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  shortlistButton: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    backgroundColor: '#fff',
    color: '#f93838',
    border: '1px solid #f93838',
    width: '100%',
    padding: '4px 8px',
    fontSize: 16,
    cursor: 'pointer',
    borderRadius: 0,
    height: '45px',

    '&:hover': {
      backgroundColor: '#f93838',
      color: '#fff'
    },
    '& span': {
      paddingTop: 2
    }
  },
  shortlistButtonActive: {
    backgroundColor: '#f93838',
    color: '#fff',
    borderRight: '1px solid #fff'
  },
  shortlistedCount: {
    backgroundColor: '#f93838',
    color: '#fff',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    width: '45px',
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 16
  }
})

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth
  }
}

export default compose(
  firestoreConnect([
    { collection: 'PublicClasses', orderBy: ['position', 'asc'] },
    { collection: 'proposals' }
  ]),
  connect(mapStateToProps, { addToDataBase })
)(withStyles(styles)(ShortlistButton))
