import React, { useCallback } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'

import * as Helper from '../util/Helper.js'
import TableStructure from './TableStructure'
import { infoTableHeaders } from '../constant.js'

const useStyles = makeStyles({
  class: {
    width: '7.5rem',
    fontWeight: 700,
    fontSize: '0.75rem'
  },
  classBox: {
    padding: '6px 4px 4px',
    textAlign: 'center',
    color: '#fff'
  },
  classHolder: {
    paddingTop: 5
  },
  juryRate: {
    padding: '4px 10px 2px',
    backgroundColor: '#e8e8e8',
    color: '#000',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

function checkNumberIsFloat(value) {
  return Number(value) === value && value % 1 !== 0
}

function getCompetitionEntryValue(proposal, categoryName) {
  const data =
    !_.isEmpty(proposal.excelData) &&
    proposal.excelData.competitionEntry.EN.length > 0
      ? proposal.excelData.competitionEntry.EN.find(
          element => element.name === categoryName
        )
      : {}
  const value = !_.isEmpty(data) ? data.value : null
  const formattedValue = checkNumberIsFloat(value) ? value.toFixed(2) : value

  return formattedValue
}

function spaceBetweenThousands(value) {
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
}

function generateInfoTableColumns(t) {
  return infoTableHeaders.map(({ header, accessor }) => {
    return {
      Header: t(header),
      accessor,
      Cell: ({ cell: { value } }) => spaceBetweenThousands(value)
    }
  })
}

function generateStatisticsValueObj(value) {
  const statisticsValueObj = {}

  infoTableHeaders.forEach(({ accessor, header }) => {
    statisticsValueObj[accessor] = getCompetitionEntryValue(value, header)
  })

  return statisticsValueObj
}

export default function InfoTable({
  Data,
  proposalsOrdered,
  PublicClasses,
  users
}) {
  const classes = useStyles()
  const { t } = useTranslation('proposals')

  const classValueHtml = useCallback(
    value => {
      return (
        <div className={classes.class}>
          {value && (
            <div
              className={classes.classBox}
              style={{ backgroundColor: value.colour }}
            >
              {value.class}
            </div>
          )}
        </div>
      )
    },
    [classes.class, classes.classBox]
  )

  const renderJuryRate = useCallback(
    value => {
      return value && !!value.overall ? (
        <div className={classes.juryRate}>{value.overall.toFixed(1)}</div>
      ) : null
    },
    [classes.juryRate]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Proposals', // Unique key and cannot remove
        columns: [
          {
            Header: t('No.'),
            accessor: 'no'
          },
          {
            Header: t('Pseudonym'),
            accessor: 'pseudonym'
          },
          {
            Header: t('Class'),
            accessor: 'proposalClass',
            disableSorting: true,
            Cell: ({ cell: { value } }) => classValueHtml(value)
          },
          {
            Header: 'R',
            accessor: 'juryRate',
            disableSorting: true,
            Cell: ({ cell: { value } }) => renderJuryRate(value)
          }
        ]
      },
      {
        Header: 'Info', // Unique key and cannot remove
        columns: generateInfoTableColumns(t)
      }
    ],
    [classValueHtml, t, renderJuryRate]
  )

  const generateArrayOfSingleRowObj = useCallback(() => {
    let singleRowObj = {}
    let statisticsValueObj = {}

    return _.map(Data, (value, key) => {
      const { number, name } = value
      const proposalClass = Helper.getProposalClass(
        key,
        proposalsOrdered,
        PublicClasses
      )
      const proposalAllRate = Helper.getProposalAllRate(users, key)
      let proposalNoNameObj = {
        no: number,
        pseudonym: name
      }

      // Add class into 'Class' column
      if (proposalClass) {
        proposalNoNameObj = {
          ...proposalNoNameObj,
          proposalClass: proposalClass
        }
      }

      // Add class into 'Class' column
      if (!_.isEmpty(proposalAllRate.rate)) {
        proposalNoNameObj = {
          ...proposalNoNameObj,
          juryRate: proposalAllRate.rate
        }
      }

      statisticsValueObj = generateStatisticsValueObj(value)

      // Combine proposal's no, name and statistic value object
      singleRowObj = {
        ...proposalNoNameObj,
        ...statisticsValueObj
      }

      return singleRowObj
    })
  }, [Data, PublicClasses, proposalsOrdered, users])

  // Only re-render when data in table is changed
  const data = React.useMemo(() => generateArrayOfSingleRowObj(), [
    generateArrayOfSingleRowObj
  ])

  return (
    <TableStructure columns={columns} data={data} disableMultiSort={true} />
  )
}
