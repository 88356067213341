import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import styled from 'styled-components'

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

const findProposalPublicData = (proposalsPublicData, key) => {
  return proposalsPublicData.find(p => p.id === key)
}

const countTrueValues = juryShortListed => {
  return Object.values(juryShortListed).filter(value => value === true).length
}

const nameToColor = (firstName, lastName) => {
  // Example implementation of nameToColor function
  const fullName = `${firstName} ${lastName}`
  let hash = 0
  for (let i = 0; i < fullName.length; i++) {
    hash = fullName.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).slice(-2)
  }
  return color
}

const generateUserColumnData = (userId, usersData) => {
  const user = usersData.find(user => user.id === userId)
  if (user) {
    const firstNameInitial = user.firstName ? user.firstName[0] : ''
    const lastNameInitial = user.lastName ? user.lastName[0] : ''
    const initials = `${firstNameInitial}${lastNameInitial}`
    const color = nameToColor(user.firstName, user.lastName)
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      initials,
      color
    }
  }
  return null
}

const getUsersColumnData = (juryShortListed, usersData) => {
  return Object.entries(juryShortListed)
    .filter(([_, value]) => value === true)
    .map(([userId, _]) => generateUserColumnData(userId, usersData))
}

const generateData = (proposals, proposalsPublicData, usersData) => {
  return Object.entries(proposals).map(([key, proposal]) => {
    const proposalPublicData = findProposalPublicData(proposalsPublicData, key)
    const shortlistingAmount = proposalPublicData?.juryShortListed
      ? countTrueValues(proposalPublicData.juryShortListed)
      : 0
    const users = proposalPublicData?.juryShortListed
      ? getUsersColumnData(proposalPublicData.juryShortListed, usersData)
      : []
    return {
      number: proposal.number,
      pseudonym: proposal.name,
      shortlistingAmount,
      users
    }
  })
}

const columnHelper = createColumnHelper()

function ShortlistedProposalsTable({ proposals, proposalsOrdered, users }) {
  const defaultData = generateData(proposals.data, proposalsOrdered, users)
  const [data, _setData] = React.useState(() => [...defaultData])
  const { t } = useTranslation('proposals')

  const columns = [
    columnHelper.accessor('number', {
      cell: info => <div className="h2 first-column">{info.getValue()}</div>,
      header: () => <div className="first-column">No.</div>,
      footer: info => info.column.id
    }),
    columnHelper.accessor(row => row.pseudonym, {
      id: 'pseudonym',
      cell: info => <i>{info.getValue()}</i>,
      header: () => <div>{t('Pseudonym')}</div>,
      footer: info => info.column.id
    }),
    columnHelper.accessor('shortlistingAmount', {
      header: () => (
        <div className="text-center">{t('Shortlisting amount')}</div>
      ),
      cell: info => <div className="text-center">{info.getValue()}</div>,
      footer: info => info.column.id
    }),
    columnHelper.accessor('users', {
      header: () => <div>{t('Users')}</div>,
      cell: info => (
        <div className="d-flex" style={{ gap: 5 }}>
          {info.getValue().map(user => (
            <div
              key={user.id}
              style={{
                borderRadius: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 20,
                height: 20,
                backgroundColor: user?.color,
                padding: 10,
                fontWeight: 'bold'
              }}
            >
              {user?.initials}
            </div>
          ))}
        </div>
      ),
      footer: info => info.column.id
    })
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <TableWrapper>
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} className="h5">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </TableWrapper>
  )
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    proposals: state.proposals,
    activeView: state.proposals.activeView,
    PublicClasses: state.firestore.ordered.PublicClasses,
    proposalsOrdered: state.firestore.ordered.proposals,
    users: state.firestore.ordered.users
  }
}

export default compose(
  firestoreConnect([
    { collection: 'proposals' },
    { collection: 'PublicClasses', orderBy: ['position', 'asc'] }
  ]),
  connect(mapStateToProps)
)(ShortlistedProposalsTable)

const TableWrapper = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead th {
    border-bottom: 1px solid #ccced0;
  }

  thead th:first-child {
    width: 10px;
  }

  th {
    text-align: left;
  }

  td {
    border-bottom: 1px solid #ccced0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .h2 {
    margin-bottom: 0;
  }

  .first-column {
    width: max-content;
    text-align: center;
  }
`
