import React from 'react'
import styled from 'styled-components/macro'
import { MdDragHandle, MdClose } from 'react-icons/md'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import Icon from './components/Icon'
import AddSampleFile from './AddSampleFile'
import EditDataTable from './EditDataTable'
import useFirestore from './hooks/useFirestore'
import ToggleSwitch from './components/ToggleSwitch'
import ItemDescription from './ItemDescription'
// import Edit3DModel from './Edit3DModel'

export default function SubmissionItem({ item, items, setItems }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id: item.id
  })
  const { updateDocument } = useFirestore('info')
  const [itemName, setItemName] = React.useState('')
  const [itemLimit, setItemLimit] = React.useState('')
  const [itemFileType, setItemFileType] = React.useState('')

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  // eslint-disable-next-line no-shadow
  const handleUpdateNameLimit = (id, name, value) => {
    const newItems = items.map(itemElement => {
      const itemId = id.split('_')[0]
      if (itemElement.id === itemId) {
        const updatedItem = {
          ...itemElement,
          [name]: value
        }
        return updatedItem
      }
      return itemElement
    })

    setItems(newItems)
  }

  const handleToggleRequired = itemId => {
    const newItems = items.map(itemElement => {
      if (itemElement.id === itemId) {
        const updatedItem = {
          ...itemElement,
          required: !itemElement.required
        }
        return updatedItem
      }
      return itemElement
    })

    updateDocument('submission', { items: newItems })
    setItems(newItems)
  }

  const handleRemoveItem = itemId => {
    const newItems = items.filter(itemElement => itemElement.id !== itemId)

    updateDocument('submission', { items: newItems })
    setItems(newItems)
  }

  const setValue = (name, value) => {
    switch (name) {
      case 'name':
        return setItemName(value)
      case 'limit':
        return setItemLimit(value)
      case 'fileType':
        return setItemFileType(value)
      default:
        return
    }
  }

  const handleChangeItem = event => {
    // eslint-disable-next-line no-shadow
    const { id, name, value } = event.target

    setValue(name, value)
    handleUpdateNameLimit(id, name, value)
  }

  const { id, displayType, unit, name, limit, fileType } = item

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (itemName || itemLimit) {
        updateDocument('submission', { items })
      }
    }, 3000)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemLimit, itemName, items])

  return (
    <div ref={setNodeRef} style={style}>
      <Item itemFileType={itemFileType}>
        <div>
          <Icon width="25px" height="25px" mr="0">
            <MdDragHandle {...attributes} {...listeners} />
          </Icon>

          {item.id.includes('table') ? (
            <EditDataTable
              items={items}
              setItems={setItems}
              tableId={item.id}
            />
          ) : null}

          {/* {item.displayType.includes('xD Twin') ? <Edit3DModel /> : null} */}
        </div>
        <TextWrapper>
          <FileType>
            <div
              style={{
                justifySelf: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <p>{displayType}</p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <ToggleSwitch
                  itemId={item.id}
                  checked={item.required}
                  onChange={() => handleToggleRequired(item.id)}
                />
                <p
                  style={{
                    margin: 0,
                    marginLeft: 5,
                    marginTop: 2,
                    fontSize: '0.65rem'
                  }}
                >
                  Required
                </p>
              </div>
            </div>
            <p style={{ justifySelf: 'center' }}>{unit}</p>
          </FileType>

          <FileDescription>
            <div style={{ justifySelf: 'start', width: '100%' }}>
              <Input
                type="text"
                placeholder="Name"
                id={`${id}_name`}
                name="name"
                onChange={handleChangeItem}
                value={name}
              />
              {displayType === 'Custom File Format' ? (
                <label style={{ color: '#6f7077' }}>
                  File type:{' '}
                  <input
                    type="text"
                    placeholder="Type"
                    id={`${id}_fileType`}
                    name="fileType"
                    onChange={handleChangeItem}
                    value={fileType}
                    style={{
                      marginTop: 10,
                      border: 0,
                      padding: '3px 10px',
                      borderBottom: '1px solid #e4e5e9',
                      backgroundColor: 'inherit'
                    }}
                  />
                </label>
              ) : null}
            </div>
            {!item.id.includes('table') ? (
              <div>
                <Input
                  type="text"
                  placeholder="Unit"
                  id={`${id}_limit`}
                  name="limit"
                  value={limit}
                  onChange={handleChangeItem}
                  style={{ textAlign: 'center' }}
                />
              </div>
            ) : null}
          </FileDescription>
          <ButtonsWrapper>
            <ItemDescription items={items} setItems={setItems} item={item} />
            <AddSampleFile items={items} setItems={setItems} item={item} />
          </ButtonsWrapper>
        </TextWrapper>

        <div style={{ justifySelf: 'end' }}>
          <Icon width="25px" height="25px" mr="0">
            <MdClose onClick={() => handleRemoveItem(item.id)} />
          </Icon>
        </div>
      </Item>
    </div>
  )
}

const Item = styled.div`
  border: 0;
  max-width: 100%;
  margin-bottom: 1rem;
  padding: 15px 20px;
  background-color: #f1f1f4;
  display: grid;
  grid-template-columns: 150px 1fr 150px;
  align-items: center;
  font-size: 1.1rem;
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-width: 200px;
  font-size: 0.8rem;
`

const FileType = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-column-gap: 2rem;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #6f7077;
`

const FileDescription = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-column-gap: 2rem;
  margin-bottom: 5px;
  text-transform: none;
  font-size: 0.8rem;
`

const Input = styled.input`
  border: 0;
  border-bottom: 1px solid #e4e5e9;
  width: 100%;
  padding-bottom: 5px;
  font-size: 1.1rem;
  background-color: inherit;

  &:focus,
  &:active {
    outline: none;
    border-bottom: 1px solid #6f7077;
  }
`

const ButtonsWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 10px;
`
